/** @format */

import AllComp from "../../views/dashboards/utility/AllComp";

const INIT_STATE = {
  dashboard: {},
  dashboardToday: {},
  loaded: true,
  allComp: {},
  instance: {},
};

const DashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_DASHBOARD_SUCCESS":
      return {
        ...state,
        dashboard: action.dashboard,
        dashboardToday: action.dashboardToday,
        instance: action.instance,
        allComp: AllComp(action.dashboard),
        loaded: action.loaded,
      };
    case "FETCH_DASHBOARD_DETAILS":
      return {
        ...state,
        [action.key]: action[action.key],
      };
    default:
      return state;
  }
};

export default DashboardReducer;
