// Layout and colors CONSTANTS
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const THEME_COLOR = 'THEME_COLOR';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const DIRECTION = 'DIRECTION';
export const BLUE_THEME = 'BLUE_THEME';
export const GREEN_THEME = 'GREEN_THEME';
export const RED_THEME = 'RED_THEME';
export const BLACK_THEME = 'BLACK_THEME';
export const PURPLE_THEME = 'PURPLE_THEME';
export const INDIGO_THEME = 'INDIGO_THEME';

// USERS CONSTANTS
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';

// USERS ATTRS
export const FETCH_USERS_ATTR_SUCCESS = 'FETCH_USERS_ATTR_SUCCESS';
export const LOAD_USER_ATTR_SUCCESS = 'LOAD_USER_ATTR_SUCCESS';

// EMAIL CONSTANTS
export const FILTER_INBOX= 'FILTER_INBOX';