/** @format */

const INIT_STATE = {
	selectedUser: [],
};

const SelectedUserReducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case "UPDATE_SELECTED":

			console.log("SelectedUserReducer", action.selectedUser)
			return {
				...state,
				selectedUser: action.selectedUser,
				success: action.success,
				loaded: action.loaded,
				persisted: action.persisted,
			};
            default:
                return state;
        }
    };
    
    export default SelectedUserReducer;