import { React } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Tooltip,
  DialogContent,
  DialogContentText,
  Box,
  CardMedia,
  Button,
} from "@material-ui/core";
import CrossIcon from "@material-ui/icons/CancelOutlined";
const EnlargeImageView = (props) => {
  const { openViewEnlargedImage, handleCloseEnlargeddView, largeImage } = props;
  const del = props.delete;
  const handleDeleteImage = props.handleDeleteImage;
  return (
    <Dialog
      open={openViewEnlargedImage}
      onClose={(e) => {
        handleCloseEnlargeddView();
      }}
    >
      <DialogTitle>
        <DialogActions>
          <Typography variant="h6" fontWeight="600">
            <Tooltip
              title="Close"
              describeChild="true"
              onClick={(e) => {
                handleCloseEnlargeddView();
              }}
            >
              <CrossIcon variant="outlined" />
            </Tooltip>
          </Typography>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* <Card> */}
          {/* <CardActions>X</CardActions> */}

          <Box>
            <CardMedia
              // sx={{ width: 3, height: 300 }}
              component="img"
              controls={false}
              src={largeImage}
            />
          </Box>
          {/* </Card> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {del ? (
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => {
              handleDeleteImage(e);
            }}
          >
            Delete
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EnlargeImageView;
