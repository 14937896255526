/** @format */

import { quizAnalysisDetails } from "./Action";

const INIT_STATE = {
  quiz: [],
  quizzes: [],
  attemptLoaded: true,
  quizAnalysisDetails: {},
  attemptQuizSuccess: "",
};

const QuizReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "UPDATE_QUIZ":
      return {
        ...state,
        quiz: action.quiz,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "QUESTIONS_UPLOADED":
      return {
        ...state,
        questionbank: action.questionbank,
        success: action.success,
        loaded: action.loaded,
      };

    case "FETCH_QUIZZES_SUCCESS":
      console.log("Quizzes Success call");
      return {
        ...state,
        quizzes: action.quizzes,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "LOAD_QUIZ_SUCCESS":
      let quiz1 = action.quiz;
      const academy = window.sessionStorage.getItem("academyQuiz");
      console.log("LOAD_QUIZ_SUCCESS", quiz1, Object.keys(quiz1));
      if (quiz1 === undefined || Object.keys(quiz1).length < 2) {
        console.log("LOAD_QUIZ_SUCCESS1", quiz1);
        return {
          ...state,
          quiz: {},
          success: action.success,
          loaded: action.loaded,
          persisted: action.persisted,
        };
      }

      if (quiz1.time === undefined || quiz1.time === null) {
        quiz1.time = "00:00 AM";
      }

      let time = quiz1.time;
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "pm" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      if (AMPM == "am" && hours == 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      quiz1.time = `2021-12-01T${sHours}:${sMinutes}:00.782`;

      //   const arr = ["startDate", "endDate", "date"];
      //   for (var i = 0; i < arr.length; i++) {
      //     console.log(quiz1.arr[i]);
      //     if (quiz1.arr[i] !== "0000-00-00") {
      //       let [year, month, date] = quiz1.arr[i].split("-");
      //       if (Number(month) < 10) month = "0" + month;
      //       if (Number(date) < 10) date = "0" + date;
      //       quiz1.arr[i] = `${year}-${month}-${date}`;
      //     }
      //   }
      if (
        quiz1.startDate !== "0000-00-00" &&
        quiz1.startDate !== null &&
        academy == "false"
      ) {
        let [year, month, date] = quiz1.startDate.split("-");
        if (Number(month) < 10 && month.length < 2) month = "0" + month;
        if (Number(date) < 10 && date.length < 2) date = "0" + date;
        quiz1.startDate = `${year}-${month}-${date}`;
      }
      if (
        quiz1.endDate !== "0000-00-00" &&
        quiz1.endDate !== null &&
        academy == "false"
      ) {
        let [year, month, date] = quiz1.endDate.split("-");
        if (Number(month) < 10 && month.length < 2) month = "0" + month;
        if (Number(date) < 10 && date.length < 2) date = "0" + date;
        quiz1.endDate = `${year}-${month}-${date}`;
      }
      if (
        quiz1.date !== "0000-00-00" &&
        quiz1.date !== null &&
        academy == "false"
      ) {
        let [year, month, date] = quiz1.date.split("-");
        if (Number(month) < 10 && month.length < 2) month = "0" + month;
        if (Number(date) < 10 && date.length < 2) date = "0" + date;
        quiz1.date = `${year}-${month}-${date}`;
      }

      return {
        ...state,
        quiz: quiz1,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "DOWNLOAD_QUESTIONS":
      return {
        ...state,
        downquesdata: action.downquesdata,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "DOWNLOAD_TEMPLATE":
      return {
        ...state,
        downquesdatatemplate: action.downquesdata,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "UPDATE_USERS_SELECTED":
      return {
        ...state,
        selected: action.selected,
      };

    case "FETCH_QUESTIONS":
      return {
        ...state,
        quesdata: action.quesdata,
        currentques: action.currentques,
        totalcorrect: action.totalcorrect,
        attemptQuizSuccess: action.attemptQuizSuccess,
      };

    case "FETCH_USER_QUIZ":
      let quizAnalysisDetailsTemp2 =
        action.analyseQuiz === ""
          ? ""
          : quizAnalysis(action.analyseQuiz, "", "");
      return {
        ...state,
        quesdata: action.quesdata,
        userquiz: action.userquiz,
        currentques: action.currentques,
        totalcorrect: action.totalcorrect,
        quizAnalysisDetails: quizAnalysisDetailsTemp2,
        analysisLoaded: false,
        attemptQuizSuccess: action.attemptQuizSuccess,
      };
    case "UPDATE_SUCCESS_IN_QUIZ":
      return {
        ...state,
        success: action.success,
      };
    case "UPDATE_QUIZ_BLANK":
      return {
        ...state,
        quiz: action.quiz,
      };
    case "UPDATE_ACADEMY_QUIZ":
      return {
        ...state,
        quiz: action.quiz,
      };
    case "UPDATE_ACADEMY_QUIZ_USER":
      return {
        ...state,
        userquiz: action.quiz,
      };
    case "ANALYSIS_DETAILS":
      let quizAnalysisDetailsTemp = quizAnalysis(
        action.quiz,
        action.userDetails,
        action.remainingTime
      );
      return {
        ...state,
        quizAnalysisDetails: quizAnalysisDetailsTemp,
        analysisLoaded: false,
      };

    default:
      return state;
  }
};

export default QuizReducer;

function quizAnalysis(q, uDetails, rTime) {
  let obj = {};
  let attempt = 0;
  let correct = 0;
  let total = q.quizDetails.length;
  q.quizDetails.map((row) => {
    if (row.user_answer !== "" && row.user_answer !== null) {
      attempt = attempt + 1;
      if (row.user_answer == row.answer) {
        correct = correct + 1;
      }
    }
  });
  obj["total"] = total;
  obj["attempted"] = attempt;
  obj["skipped"] = total - attempt;
  obj["correct"] = correct;
  obj["wrong"] = attempt - correct;
  obj["attemptPercent"] = (attempt / total) * 100;
  obj["correctPercent"] = (correct / attempt) * 100;
  obj["score"] = correct * q.score - (attempt - correct) * q.negative;
  obj["quiz"] = q;
  return obj;
}
