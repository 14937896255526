import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardContent,
  Box,
  Button,
  Typography,
  CardMedia,
  Dialog,
  DialogTitle,
  Slide,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  FormLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import PageContainer from "../../../components/container/PageContainer";
import Breadcrumb from "../../../layouts/full-layout/breadcrumb/Breadcrumb";
import CustomSlider from "../../../components/forms/custom-elements/CustomSlider";
import { loadUserCourse } from "../../../redux/courses/Action";
import {
  updateCourseQuiz,
  persistUserCourse,
  loadCurrentQuiz,
  persistCourseQuiz,
  updateUserQuiz,
  nextPageDocument,
} from "../../../redux/courses/Action";
import Spinner from "../../spinner/Spinner";
import CustomTextField from "../../../components/forms/custom-elements/CustomTextField";
import CustomFormLabel from "../../../components/forms/custom-elements/CustomFormLabel";
import CustomRadio from "../../../components/forms/custom-elements/CustomRadio";
import { fetchInbox, earningConfig } from "../../../redux/inbox/Action";
import { useNavigate } from "react-router-dom";
import EnlargeImageView from "../../components/EnlargedImageView";
import EnlargeImageViewMultiple from "../../components/EnlargedImageViewMultiple";
import FeatherIcon from "feather-icons-react";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));
window.Buffer = window.Buffer || require("buffer").Buffer;
const Course = () => {
  const [docspath, setDocsPath] = useState("None");
  const [open, setOpen] = React.useState(false);
  const [answer, setAnswer] = React.useState("");
  const [errMsg, setErrMsg] = React.useState("");
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [videoLength, setVideoLength] = useState(0);
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [videoEndTime, setVideoEndTime] = useState(0);
  const [largeImage, setLargeImage] = useState("none");
  const [playStatus, setPlayStatus] = useState(true);
  const [visitedDocArray, setVisitedDocArray] = useState([]);
  const [playCount, setPlayCount] = useState(1);
  const [openViewEnlargedImage, setOpenViewEnlargedImage] = useState(false);
  const [videoPath, setVideoPath] = useState("");
  const [openQuesitonDialog, setOpenQuestionDialog] = React.useState(false);
  const refContainerModal = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("answer", answer);
  useEffect(() => {
    // console.log("called");
    dispatch(earningConfig());
  }, []);
  const itemid = useSelector((state) => state.inboxReducer.itemid);
  const programId = useSelector((state) => state.inboxReducer.programId);
  const earningConfigration = useSelector((state) => state.inboxReducer.config);
  let completionTimeFactor;
  let completionEarningFactor;
  if (earningConfigration != undefined) {
    completionTimeFactor =
      earningConfigration.CompletionDuration.split(",").map(Number);
    completionEarningFactor =
      earningConfigration.EarningsByDuration.split(",").map(Number);
    // console.log(
    //   "config",
    //   completionEarningFactor,
    //   completionTimeFactor,
    //   typeof completionTimeFactor[2],
    //   typeof completionEarningFactor[2]
    // );
  }
  const setDocumentsPath = (filepath) => {
    if (filepath !== undefined && course.activityType === "Document") {
      const last_segment = filepath.split("/").pop();
      const chunks = last_segment.split("-");
      const counter = chunks[1];
      let docs = [];
      let i = 0;
      while (i < counter) {
        docs.push(
          "https://kimappvideos.s3.ap-south-1.amazonaws.com/docs/" +
            chunks[0] +
            "/image" +
            i +
            ".jpg"
        );
        i++;
      }
      setDocsPath(docs);
    }
  };

  useEffect(() => {
    dispatch(loadUserCourse(itemid));
  }, [dispatch]);

  const course = useSelector((state) => state.courseReducer.course);

  useEffect(() => {
    console.log("use");
    setDocumentsPath(course.url);
    setVideoPath(course.url);
  }, [course.url]);

  //console.log("coursee in coursejs", course);

  let currentpage = 0;
  currentpage = useSelector((state) => state.courseReducer.currentpage);
  if (currentpage === undefined) {
    currentpage = 0;
  }

  let quizzes = {};

  if (course !== undefined || course !== null) {
    quizzes = course.quizzes;
  }

  let currentquiz = useSelector((state) => state.courseReducer.currentquiz);
  const answerArray = useSelector((state) => state.courseReducer.answerArray);

  //console.log("answerArray", answerArray);
  if (currentquiz === undefined || currentquiz === null) {
    currentquiz = course.quizzes[0];
  }

  let loaded = useSelector((state) => state.courseReducer.loaded);

  const success = useSelector((state) => state.courseReducer.success);

  const handleClose = () => {
    setOpen(false);
    setVideoEndTime(new Date().getTime() / 1000);
  };
  // console.log(
  //   "test time",
  //   videoLength,
  //   videoEndTime,
  //   videoStartTime,
  //   videoEndTime - videoStartTime
  // );
  console.log(videoLength);
  const handleSave = (e, course) => {
    course.quizzes.forEach(myFunction);
    var answerCount = 0;
    function myFunction(item, index) {
      for (var i = 0; i < answerArray.length; i++) {
        const [time, answer] = answerArray[i].split("#");
        if (Object.keys(item) == time) {
          course.quizzes[index][time]["user_answer"] = answer;
          if (course.quizzes[index][time] === answer) {
            answerCount = answerCount + 1;
          }
        }
      }
    }
    const d = new Date();
    let earning = answerCount * 1;
    console.log("Earning1", earning, answerCount);
    course["submitTime"] = d;
    let h1 = d.getHours();
    let m1 = d.getMinutes();
    let time1 = course.time;
    const [time, AMPM] = time1.split(" ");

    let [hours, minutes] = time.split(":");
    if (AMPM === "PM" && parseInt(hours) !== 12) {
      hours = parseInt(hours) + 12;
      minutes = parseInt(minutes);
    } else if (AMPM === "AM" && parseInt(hours) === 12) {
      hours = 0;
      minutes = parseInt(minutes);
    } else {
      hours = parseInt(hours);
      minutes = parseInt(minutes);
    }
    let totalminutes1 = h1 * 60 + m1;
    let totalminutes2 = hours * 60 + minutes;

    const completionTimeInMinutes = totalminutes1 - totalminutes2;
    const completeioTimeInHour = completionTimeInMinutes / 60;

    if (completionTimeInMinutes < 0) {
      earning = earning + 1;
    } else {
      for (var i = 0; completionTimeFactor.length - 1; i++) {
        if (completeioTimeInHour <= completionTimeFactor[i]) {
          earning = earning + completionEarningFactor[i];

          break;
        }
      }
    }

    course["earnings"] = earning;
    if (programId !== null) {
      course["program_id"] = programId;
      //course["activityType"] = "course";
    } else {
      course["program_id"] = null;
    }
    console.log("Course Deatils", course, answerCount);
    const watchTime = videoEndTime - videoStartTime;
    if (course.activityType === "Document") {
      const visitedDockSet = new Set(visitedDocArray);
      // console.log(
      //   "test document",
      //   visitedDocArray,
      //   visitedDockSet,
      //   visitedDocArray.length,
      //   visitedDockSet.size
      // );
      let allDocs = [];
      docspath.map((row, index) => {
        allDocs.push(index + 1);
      });
      // console.log("all docks", allDocs);
      if (visitedDockSet.size === docspath.length) {
        dispatch(persistUserCourse(course));
        setTimeout(() => {
          dispatch(fetchInbox());
        }, 1000);
        navigate("/inbox");
        console.log("pass");
        setErrMsg("");
        //console.log("Full video watched.");
      } else {
        const visitedArray = Array.from(visitedDockSet);
        const notVisited = allDocs.filter((n) => !visitedArray.includes(n));
        let commaSeperatedNotVisited = notVisited.join(",");
        console.log("test", visitedArray, notVisited, commaSeperatedNotVisited);
        setErrMsg(
          "Please read page No " +
            commaSeperatedNotVisited +
            " and then mark as complete."
        );
      }
    } else {
      console.log(
        "status",
        course.activityType == "Document",
        course.activityType,
        watchTime,
        videoLength
      );
      if (
        false
        // watchTime < videoLength &&
        // window.sessionStorage.getItem("tenantId") != "8"
      ) {
        setErrMsg("Please watch full video and then mark as complete.");

        //console.log("Full video watched.");
      } else {
        dispatch(persistUserCourse(course));
        setTimeout(() => {
          dispatch(fetchInbox());
        }, 1000);
        navigate("/inbox");
        setErrMsg("");
      }
    }
  };

  const handleEditQuestionSave = () => {
    //console.log("answer test", answer);
    if (answer === "" || answer === undefined) {
      setErrMsg("Please select an answer.");
    } else {
      setErrMsg("");
      dispatch(updateUserQuiz(course, currentquiz.time, answer, answerArray));
      //dispatch(persistCourseQuiz(quizzes, currentquiz, currentquiz.time, 'save'))
      setOpenQuestionDialog(false);
      refContainerModal.current.play();
      setAnswer("");
    }
  };

  const handleEditQuestionDelete = () => {
    dispatch(
      persistCourseQuiz(quizzes, currentquiz, currentquiz.time, "delete")
    );
    setOpenQuestionDialog(false);
  };

  const handleEditQuestionClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      alert("Please answer.");
    } else {
      setOpenQuestionDialog(false);
    }
  };

  const handleBackDropClick = () => {
    //console.log("handle called");
    setOpenQuestionDialog(true);
  };

  const handleStepClick = (e, key) => {
    //console.log("handleStepClick");
    //console.log(key);
    refContainerModal.current.pause();
    dispatch(loadCurrentQuiz(quizzes, key, -1));
    setTimeout(() => {
      setOpenQuestionDialog(true);
      refContainerModal.current.currentTime = key;
    }, 10);
  };
  const course1 = course;
  //console.log("course1", course1);
  const onFullScreen = (evt, play) => {
    setVideoPlayed(true);
    setVideoLength(document.getElementById("myVideo").duration);
    setVideoStartTime(new Date().getTime() / 1000);
    setOpen(true);
    setErrMsg("");
    const interval = setInterval(() => {
      let t =
        refContainerModal.current === null
          ? 0
          : refContainerModal.current.currentTime;
      setTime(t);
      //currentquiz['time'] = t;
      //console.log("quizzes", quizzes);
      if (quizzes !== undefined) {
        quizzes.forEach((quiz) => {
          for (var q in quiz) {
            //console.log("Time");
            if (Math.round(q - t) == 0 && quiz[q].visited == undefined) {
              quiz[q].visited = true;
              //console.log(quiz[q].visited);
              //console.log("Match", quiz[q]);

              refContainerModal.current.pause();
              currentquiz = quiz[q];
              //console.log("quiz", course1);

              dispatch(loadCurrentQuiz(course1, quiz[q], -1));
              //console.log("currentquiz", currentquiz);
              setOpenQuestionDialog(true);
              //console.log("Should have worked", quiz[q]);
            }
          }
        });
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  };

  const editQuiz = (evt) => {
    refContainerModal.current.pause();
    dispatch(
      loadCurrentQuiz(quizzes, refContainerModal.current.currentTime, -1)
    );
    //console.log("currentquiz", currentquiz);
    setOpenQuestionDialog(true);
  };

  let duration = 100;
  if (
    refContainerModal.current !== undefined &&
    refContainerModal.current !== null
  ) {
    if (
      refContainerModal.current.currentTime ===
        refContainerModal.current.duration &&
      playCount === 1
    ) {
      setPlayStatus(false);
      setPlayCount(2);
    }
  }
  if (
    refContainerModal.current !== undefined &&
    refContainerModal.current !== null
  ) {
    duration = refContainerModal.current.duration;
  }

  const onPlayModal = (e, play) => {
    duration = refContainerModal.current.duration;
    console.log(
      "corent",
      refContainerModal.current.currentTime,
      refContainerModal.current.duration
    );
    if (play === "Play") {
      refContainerModal.current.play();
      setPlayStatus(true);
      setPlayCount(1);
    } else if (play === "Pause") {
      refContainerModal.current.pause();
      setPlayStatus(false);
      setPlayCount(1);
    } else if (play === "ReStart") {
      refContainerModal.current.currentTime = 0;
      playStatus
        ? refContainerModal.current.play()
        : refContainerModal.current.pause();
      setPlayCount(1);
    } else if (play === "forward") {
      refContainerModal.current.currentTime =
        refContainerModal.current.currentTime + 5;
      playStatus
        ? refContainerModal.current.play()
        : refContainerModal.current.pause();
      setPlayCount(1);
    } else if (play === "backward") {
      refContainerModal.current.currentTime =
        refContainerModal.current.currentTime - 5;
      playStatus
        ? refContainerModal.current.play()
        : refContainerModal.current.pause();
      setPlayCount(1);
    } else {
      refContainerModal.current.pause();
    }
  };

  const [time, setTime] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     let t = refContainerModal.current === null? 0 : refContainerModal.current.currentTime;
  //     setTime(t);
  //     currentquiz['time'] = t;
  //     console.log("quizzes", quizzes);
  //     if (quizzes !== undefined) {

  //     quizzes.forEach((quiz) => {
  //       for (var q in quiz) {
  //           console.log("Time");
  //           if (Math.round(q-t) == 0){
  //             console.log("Match", q);
  //           }
  //       }
  //     });
  //   }
  //   }, 1);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);
  // const countVideoLength = (count) => {
  //   console.log("count video", count);
  // };

  const handleImageClicked = (imageUrl, current) => {
    console.log("image Url called", current);
    setLargeImage(imageUrl);
    setOpenViewEnlargedImage(true);
    let tempArr = visitedDocArray;
    tempArr.push(current);
    setVisitedDocArray(tempArr);
    //visitedDocArray.push(current);
  };
  //console.log("lRGE Image", largeImage, openViewEnlargedImage, visitedDocArray);
  const handleCloseEnlargeddView = () => {
    setOpenViewEnlargedImage(false);
    console.log("image Url called 2", openViewEnlargedImage);
  };
  const handleNextPage = () => {
    handleImageClicked(docspath[currentpage + 1], currentpage + 1);
    dispatch(nextPageDocument(currentpage, docspath.length));
  };
  const handlePreviousPage = () => {
    handleImageClicked(docspath[currentpage - 1], currentpage - 1);
    dispatch(nextPageDocument(currentpage - 2, docspath.length));
  };

  let rows = quizzes === undefined ? {} : quizzes;
  loaded = true;
  return !loaded ? (
    <Spinner />
  ) : course.activityType !== "Video" ? (
    <div>
      <PageContainer
        title="Inbox Item Details"
        description="this is inbox page"
      >
        <Breadcrumb title="Course" subtitle="Look at Inbox Item" />
        <Card variant="outlined">
          <Typography
            variant="h5"
            sx={{ color: (theme) => theme.palette.error.main }}
          >
            {errMsg}
          </Typography>
          <CardContent>
            <Box sx={{ height: "25%" }}>
              <CardMedia
                component="img"
                //autoPlay
                sx={{ width: 200 }}
                controls={false}
                src={docspath[currentpage]}
                // onClick={(e) => {
                //   handleImageClicked(docspath[currentpage], currentpage);
                // }}
              />
            </Box>
            {/* <FormLabel color="error" error={true}>
              <i>Please click on page to see enlarged view.</i>
            </FormLabel> */}
            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "flex",
                  lg: "flex",
                },
                alignItems: "center",
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={(e) => {
                  handleImageClicked(docspath[currentpage], currentpage);
                }}
              >
                Launch Course
              </Button>
              {/* <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={(e) => {
                  dispatch(nextPageDocument(currentpage - 2, docspath.length));
                }}
              >
                Previous Page
              </Button>
              <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={(e) => {
                  dispatch(nextPageDocument(currentpage, docspath.length));
                }}
              >
                Next Page
              </Button> */}
              <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={(e) => handleSave(e, course)}
              >
                Mark as Complete
              </Button>
            </Box>
          </CardContent>
        </Card>
        <EnlargeImageViewMultiple
          openViewEnlargedImage={openViewEnlargedImage}
          handleCloseEnlargeddView={handleCloseEnlargeddView}
          largeImage={largeImage}
          totalImage={docspath.length}
          current={currentpage + 1}
          handleNext={handleNextPage}
          handlePrevious={handlePreviousPage}
        />
      </PageContainer>
    </div>
  ) : (
    <PageContainer title="Inbox Item Details" description="this is inbox page">
      <Breadcrumb title="Course" subtitle="Look at Inbox Item" />
      <Card variant="outlined">
        <Typography
          variant="h5"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {errMsg}
        </Typography>
        <CardContent>
          <Box sx={{ height: "100%" }}>
            {/* <CardMedia
              component="video"
              //autoPlay
              //onProgress={countVideoLength}
              sx={{ width: 300 }}
              controls={false}
              src={course?.url}
            /> */}
            <video id="myVideo" width="320" height="240">
              <source src={videoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
          <Typography
            variant="h5"
            sx={{ color: (theme) => theme.palette.success.main }}
          >
            {success}
          </Typography>

          <Box
            sx={{
              display: {
                xs: "block",
                sm: "flex",
                lg: "flex",
              },
              alignItems: "center",
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              sx={{
                mb: {
                  xs: 1,
                  sm: 0,
                  lg: 0,
                },
                ml: {
                  xs: 1,
                  sm: 1,
                  lg: 1,
                },
              }}
              onClick={(e) => onFullScreen(e, "FullScreen")}
            >
              Launch and Attempt
            </Button>
            <Button
              variant="contained"
              sx={{
                mb: {
                  xs: 1,
                  sm: 0,
                  lg: 0,
                },
                ml: {
                  xs: 1,
                  sm: 1,
                  lg: 1,
                },
              }}
              onClick={(e) => handleSave(e, course)}
              disabled={!videoPlayed}
            >
              Mark as Complete
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose} fullScreen={true}>
        <form>
          <DialogContent>
            <Box>
              <CardMedia
                ref={refContainerModal}
                component="video"
                //autoPlay
                sx={{
                  width: window.innerWidth,
                  height: window.innerHeight - 150,
                }}
                //onProgress={countVideoLength}
                //onDurationChange={countVideoLength}
                controls={false}
                src={course?.url}
                autoPlay
              />
            </Box>
            <CustomSlider value={(100 / duration) * time} />
            {/* <Stepper nonLinear>
              {Object.keys(rows).map(function(keyName, keyIndex) {
                return (
                  <Step expanded={true} active={true} key={keyName}>
                    <StepButton
                      onClick={(e) => handleStepClick(e, keyName)}
                    >
                    </StepButton>
                  </Step>
              )})}
            </Stepper> */}
          </DialogContent>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Re-start">
                <IconButton onClick={(e) => onPlayModal(e, "ReStart")}>
                  <FeatherIcon icon="rotate-ccw" width="30" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Rewind">
                <IconButton onClick={(e) => onPlayModal(e, "backward")}>
                  <FeatherIcon icon="rewind" width="30" />
                </IconButton>
              </Tooltip>
              <Tooltip title={!playStatus ? "play" : "pause"}>
                <IconButton
                  onClick={(e) =>
                    onPlayModal(e, !playStatus ? "Play" : "Pause")
                  }
                >
                  <FeatherIcon
                    icon={!playStatus ? "play" : "pause"}
                    width="30"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Forward">
                <IconButton onClick={(e) => onPlayModal(e, "forward")}>
                  <FeatherIcon icon="fast-forward" width="30" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton onClick={(e) => handleClose()}>
                  <FeatherIcon icon="x-circle" width="30" />
                </IconButton>
              </Tooltip>
              {/* <Button
                variant="contained"
                color="primary"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                }}
                onClick={(e) => onPlayModal(e, "ReStart")}
              >
                ReStart
              </Button>
              <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={(e) => onPlayModal(e, "backward")}
              >
                Backward
              </Button>
              <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={(e) => onPlayModal(e, !playStatus ? "Play" : "Pause")}
              >
                {!playStatus ? "Play" : "Pause"}
              </Button>
              <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={(e) => onPlayModal(e, "forward")}
              >
                Forward
              </Button>
              <Button
                variant="contained"
                sx={{
                  mb: {
                    xs: 1,
                    sm: 0,
                    lg: 0,
                  },
                  ml: {
                    xs: 1,
                    sm: 1,
                    lg: 1,
                  },
                }}
                onClick={handleClose}
              >
                Close
              </Button> */}
            </Box>
          </DialogTitle>
        </form>
      </Dialog>
      <Dialog
        open={openQuesitonDialog}
        TransitionComponent={Transition}
        onClose={(e, s) => {
          handleEditQuestionClose(e, s);
        }}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onBackdropClick={handleBackDropClick}
      >
        <DialogTitle id="alert-dialog-slide-title" variant="h4">
          Quiz Questions
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            component="div"
          >
            <Card>
              <Typography
                variant="h5"
                sx={{ color: (theme) => theme.palette.error.main }}
              >
                {errMsg}
              </Typography>
              <CustomFormLabel htmlFor="to-text">Question</CustomFormLabel>
              <CustomTextField
                id="to-text"
                placeholder="Enter Question"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                size="small"
                variant="outlined"
                value={currentquiz?.time?.question}
                onChange={(e) => {
                  dispatch(
                    updateCourseQuiz(
                      quizzes,
                      currentquiz,
                      refContainerModal.current.currentTime,
                      "question",
                      e.target.value
                    )
                  );
                }}
              />
              <CustomFormLabel htmlFor="subject-text">
                Please select the Answer
              </CustomFormLabel>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={10} sm={10} lg={10}>
                    <CustomTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="name"
                      placeholder="Enter Option 1"
                      value={currentquiz?.time?.choice[0]}
                      onChange={(e) => {
                        dispatch(
                          updateCourseQuiz(
                            quizzes,
                            currentquiz,
                            refContainerModal.current.currentTime,
                            "option1",
                            e.target.value
                          )
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                    <CustomTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="name"
                      placeholder="Enter Option 2"
                      value={currentquiz?.time?.choice[1]}
                      onChange={(e) => {
                        dispatch(
                          updateCourseQuiz(
                            quizzes,
                            currentquiz,
                            refContainerModal.current.currentTime,
                            "option2",
                            e.target.value
                          )
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                    <CustomTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="name"
                      placeholder="Enter Option 3"
                      value={currentquiz?.time?.choice[2]}
                      onChange={(e) => {
                        dispatch(
                          updateCourseQuiz(
                            quizzes,
                            currentquiz,
                            refContainerModal.current.currentTime,
                            "option3",
                            e.target.value
                          )
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                    <CustomTextField
                      InputProps={{
                        readOnly: true,
                      }}
                      id="name"
                      placeholder="Enter Option 4"
                      value={currentquiz?.time?.choice[3]}
                      onChange={(e) => {
                        dispatch(
                          updateCourseQuiz(
                            quizzes,
                            currentquiz,
                            refContainerModal.current.currentTime,
                            "option4",
                            e.target.value
                          )
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} lg={2}>
                    <RadioGroup
                      aria-label="gender"
                      value={answer}
                      name="radio-buttons-group"
                      onChange={(e) => {
                        setAnswer(e.target.value);
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value={currentquiz?.time?.choice[0]}
                          control={<CustomRadio />}
                          label=""
                        />
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value={currentquiz?.time?.choice[1]}
                          control={<CustomRadio />}
                          label=""
                        />
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value={currentquiz?.time?.choice[2]}
                          control={<CustomRadio />}
                          label=""
                        />
                      </FormControl>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value={currentquiz?.time?.choice[3]}
                          autoComplete={"off"}
                          control={<CustomRadio />}
                          label=""
                        />
                      </FormControl>
                    </RadioGroup>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditQuestionSave}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          {/* <Button 
            onClick={handleEditQuestionDelete}
            color="primary" 
            variant="contained"
          >
            Delete
          </Button>
          <Button onClick={handleEditQuestionClose} color="secondary">
            Cancel
          </Button> */}
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default Course;
