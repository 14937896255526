
const INIT_STATE = {
  users: [],
};

const RolesReducer = (state = INIT_STATE, action) => {

  switch (action.type) {
    case "FETCH_ROLES_SUCCESS":
      return {
        ...state,
        roles: action.roles,
        loaded: true,
        success: "",
      };

    case 'LOAD_PERMS_SUCCESS':
       console.log('LOAD_PERMS_SUCCESS');
       console.log(action.perms);
       return {
        ...state,
        perms: action.perms,
        loaded: action.loaded,
        success: action.success,
      }; 
      
    case 'LOAD_Role_SUCCESS':
       return {
        ...state,
        role: action.role,
        loaded: action.loaded,
        success: action.success,
      }; 

    case 'UPDATE_Role_SUCCESS':
       return {
        ...state,
        role: action.role,
        loaded: action.loaded,
        success: action.success,
      }; 

    case 'PERSIST_ROLE':
       return {
        ...state,
        role: action.role,
        loaded: action.loaded,
        success: action.success,
      }; 

    default:
      return state;

  }
};

export default RolesReducer;
