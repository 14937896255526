/** @format */

import {
  FETCH_USERS_SUCCESS,
  LOAD_USER_SUCCESS,
  FETCH_USERS_ATTR_SUCCESS,
  LOAD_USER_ATTR_SUCCESS,
} from "../constants";

const INIT_STATE = {
  users: [],
  notes: [],
  notesContent: 1,
  noteSearch: "",
  token: "",
  mobNumber: [],
  filterAttrs: [],
  Locs: [],
  Depts: [],
  Desigs: [],
  deActiveUserMob: [],
  initialRows: [],
  tenantList: [],
  userattrs: [],
  tenantDetails: {},
  tenantLoaded: false,
  success: "",
  tenantSuccess: "",
  tenant_name: "",
  logo: "",
  atrLoaded: false,
  successMsg: "",
  tenant_id: "",
  uploadAttrs: {},
  adminList: [],
  learnerList: [],
  reAssignSuccess: [],
};

const UsersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      console.log("user atr", state.userattrs);
      let InitialRows = changeAttribute(action.users, state.userattrs);
      let userType = filterUserType(action.users);
      let mobl = mobileNumber(action.users);
      console.log("reducer called", userType, InitialRows);
      return {
        ...state,
        users: action.users,
        initialRows: InitialRows.rows,
        filterAttrs: InitialRows.filter_rows,
        mobNumber: mobl,
        adminList: userType.admin,
        learnerList: userType.learner,
        loaded: action.loaded,
      };

    case FETCH_USERS_ATTR_SUCCESS:
      let atr = getAttribute(action.userattrs);
      return {
        ...state,
        userattrs: action.userattrs,
        uploadAttrs: atr,
        atrLoaded: action.loaded,
        success: "",
      };

    case LOAD_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        loaded: true,
        success: action.success,
      };
    case "FETCH_USERS_DEACTIVATED":
      let tempmob = mobileNumber(action.deActiveUsers);
      console.log(tempmob);
      console.log(action.deActiveUsers);
      return {
        ...state,
        deActiveUserMob: tempmob,
        //loaded: action.loaded,
      };

    case "LOGIN_USER_SUCCESS":
      return {
        ...state,
        user: action.user,
        loaded: action.loaded,
        tenant_name: action.tenant_name,
        tenant_id: action.tenant_id,
        logo: action.tenant_logo,
        success: action.success,
      };

    case LOAD_USER_ATTR_SUCCESS:
      console.log(action.userattr);
      return {
        ...state,
        userattr: action.userattr,
        atrLoaded: action.loaded,
        success: action.success,
      };

    case "DELETE_USER":
      return {
        ...state,
        user: action.user,
        loaded: action.loaded,
        successMsg: action.success,
      };

    case "ADD_USER":
      return {
        ...state,
        user: action.user,
      };

    case "UPDATE_USER":
      return {
        ...state,
        user: action.user,
      };

    case "PERSIST_USER":
      return {
        ...state,
        user: action.user,
        //loaded: action.loaded,
        successMsg: action.success,
      };

    case "UPDATE_LOADED":
      return {
        ...state,
        user: action.user,
        loaded: action.loaded,
        success: action.success,
      };

    case "UPDATE_USER_ATTR":
      // console.log(`UPDATE_USER${action.user}`);
      console.log(action.userattr);
      // const temp = state.user.map(function(user){return (user.id === action.user.id)? action.user: user});
      return {
        ...state,
        userattr: action.userattr,
      };

    case "PERSIST_USER_ATTR":
      return {
        ...state,
        userattr: action.userattr,
        //loaded: action.loaded,
        successMsg: action.success,
      };
    case "FETCH_USER_HELPS":
      console.log("userHelpReducer", state.users);
      let help = assignUserInHelp(action.userHelps, state.adminList);
      console.log("help2", help);
      return {
        ...state,
        userHelps: help,
        loaded: action.loaded,
        persist: action.persist,
      };
    case "PERSIST_USER_HELP":
      return {
        ...state,
        successMsg: action.success,
        loaded: action.loaded,
      };
    case "FETCH_CONVERSATION":
      return {
        ...state,
        conversation: action.conversation,
        converLoaded: action.converLoaded,
      };
    case "UPDATE_INITIALROW":
      return {
        ...state,
        initialRows: action.initialRows,
        loaded: action.loaded,
      };
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        successMsg: action.success,
      };
    case "UPDATE_TOKEN":
      return {
        ...state,
        token: action.token,
      };
    case "FETCH_TENANTLIST":
      return {
        ...state,
        tenantList: action.tenantList,
        tenantLoaded: action.tenantLoaded,
      };
    case "FETCH_TENANT":
      return {
        ...state,
        tenantDetails: action.tenantDetails,
        tenantLoaded: action.tenantLoaded,
      };
    case "UPDATE_TENANT":
      console.log("tenant details", action.tenantDetails);
      return {
        ...state,
        tenantDetails: action.tenantDetails,
      };
    case "PERSIST_TENANT":
      return {
        ...state,
        tenantDetails: action.tenantDetails,
        tenantLoaded: action.tenantLoaded,
        tenantSuccess: action.success,
      };
    case "UPDATE_SUCCESS_USER":
      return {
        ...state,
        tenantSuccess: action.success,
      };
    case "PERSIST_HELPDESK_REASSIGN":
      return {
        ...state,
        reAssignSuccess: action.success,
      };
    case "FETCH_TENANT_USER":
      console.log("fetch tenant usr", action.tenantUser);
      let tempUser = {};
      if (action.tenantUser == undefined) {
        tempUser = {
          id: -1,
          phone_num: "",
          password: "",
          repassword: "",
          original_password: "",
          first_name: "",
          last_name: "",
          role_id: 1,
          tenant_id: action.tenant_id,
          created_at: "",
          status: "Active",
          email_id: "",
          aadhar_num: "",
          pan_no: "",
        };
      } else {
        tempUser = action.tenantUser;
        tempUser.original_password = tempUser.password;
        tempUser.repassword = tempUser.password;
      }
      return {
        ...state,
        tenantUser: tempUser,
        tenantLoaded: action.tenantLoaded,
      };
    case "UPDATE_TENANT_USER":
      console.log("tenant user", action.tenantUser);
      return {
        ...state,
        tenantUser: action.tenantUser,
      };
    case "PERSIST_TENANT_USER":
      console.log("tenant".action);
      return {
        ...state,
        tenantUser: action.tenantUser,
        tenantLoaded: action.tenantLoaded,
        tenantSuccess: action.success,
      };
    case "UPDATE_TENANT_NAME":
      return {
        ...state,
        tenant_name: action.tenant_name,
        tenant_id: action.tenant_id,
      };
    case "SET_USER_LOCATION":
      return {
        ...state,
        locationReturn: action.locationReturn,
      };
    default:
      return state;
  }
};

// function changeAttribute(data){
//     let details = {};
//     data.map((row)=>{
//       details[row.attr] = row.value;
//     });
//     return details;
//   }
export default UsersReducer;

function changeAttribute(users, attrs) {
  let rowsChange = [];
  let returnObj = {};
  //console.log("attr", attrs);
  let filterAttrs = [
    { name: "First Name" },
    { name: "Last Name" },
    { name: "Mob. No." },
  ];
  let attributes = attrs.map((row) => ({
    id: row.id,
    name: row.name,
  }));
  // rowsChange = users.map((rec) => ({
  //   id: rec.id,
  //   first_name: rec.first_name,
  //   last_name: rec.last_name,
  //   phone_num: rec.phone_num,
  //   // Location: rec.attr["1"] ? rec.attr["1"] : "Not Assign",
  //   // Department: rec.attr["2"] ? rec.attr["2"] : "Not Assign",
  //   // Designation: rec.attr["3"] ? rec.attr["3"] : "Not Assign",

  //   role_id: rec.role_id,
  //   active: rec.active,
  // }));
  users.map((rec) => {
    let tempObj = {};

    tempObj.id = rec.id;
    tempObj.first_name = rec.first_name;
    tempObj.last_name = rec.last_name;
    tempObj.phone_num = rec.phone_num;
    tempObj.role_id = rec.role_id;
    tempObj.active = rec.active;
    attributes.map((row) => {
      tempObj[row.name] = rec.attr[row.id];
    });
    rowsChange.push(tempObj);
    //console.log("tempObj", tempObj);
  });

  attributes.map((row) => {
    let tempObj = {};
    tempObj["name"] = row.name;
    console.log("asdasdasd", attributes, filterAttrs);
    filterAttrs.push(tempObj);
  });
  //console.log("asdasdasd", rowsChange, filterAttrs);
  returnObj["rows"] = rowsChange;
  returnObj["filter_rows"] = filterAttrs;
  return returnObj;
}

function mobileNumber(data) {
  let mob = [];

  data.map((row) => {
    mob.push(row.phone_num);
  });
  return [...new Set(mob)];
}

function getAttribute(data) {
  let obj = {};

  data.map((row) => {
    obj[row.name] = {};
    obj[row.name]["id"] = row.id;
    obj[row.name]["val"] = [];
    row.attr.map((row2) => {
      obj[row.name]["val"].push(row2.value);
    });
  });
  console.log("val", obj);
  // obj["Locs"] = Locs;
  // obj["Dept"] = Dept;
  // obj["Desig"] = Desig;
  return obj;
}

function filterUserType(data) {
  let admin = [];
  let learner = [];
  let finalObj = {};
  data.map((row) => {
    if (row.role_id == "1") {
      let tempObj = {};
      tempObj["name"] = `${row.first_name} ${row.last_name}`;
      tempObj["id"] = row.id;
      admin.push(tempObj);
    } else {
      let tempObj = {};
      tempObj["name"] = `${row.first_name} ${row.last_name}`;
      tempObj["id"] = row.id;
      learner.push(tempObj);
    }
  });
  finalObj["admin"] = admin;
  finalObj["learner"] = learner;
  return finalObj;
}

function assignUserInHelp(helpDesk, usersData) {
  //console.log("help", helpDesk, usersData);
  let tempRow = [];
  helpDesk.map((row, index) => {
    if (row.assigned_to === null) {
      helpDesk[index]["assigned_to_name"] = "";
      helpDesk[index]["assigned_by_name"] = "";
    } else {
      usersData.map((row1) => {
        console.log(
          "test",
          row1.id == row.assigned_to,
          row1.id,
          row.assigned_to,
          row1
        );
        if (row1.id == row.assigned_to) {
          helpDesk[index]["assigned_to_name"] = row1.name;
        }
        if (row1.id == row.assigned_by) {
          helpDesk[index]["assigned_by_name"] = row1.name;
        }
      });
      // helpDesk[index]["assigned_to_name"] = usersData.map(row);
      // helpDesk[index]["assigned_by_name"] = "";
    }
  });
  console.log("helpDesk", helpDesk);
  return helpDesk;
}
