import { combineReducers } from "redux";
import CustomizerReducer from "./customizer/CustomizerReducer";
import usersReducer from "./users/UsersReducer";
import rolesReducer from "./roles/RolesReducer";
import quizReducer from "./quiz/QuizReducer";
import inboxReducer from "./inbox/InboxReducer";
import taskReducer from "./tasks/TaskReducer";
import adminReducer from "./admin/AdminReducer";
import certificateReducer from "./certificate/CertificateReducer";
import categoriesReducer from "./category/CategoriesReducer";
import courseReducer from "./courses/CourseReducer";
import selectedUserReducer from "./selectedUser/SelectedUserReducer";
import userActivityReducer from "./userActivity/UserActivityReducer";
import questionBankReducer from "./quesBank/QuestionBankReducer";
import learningProgramReducer from "./learningPrograms/LearningProgramReducer";
import passwordReducer from "./resetPassword/PasswordReducer";
import dashboardReducer from "./dashboard/DashboardReducer";

const RootReducers = combineReducers({
  CustomizerReducer,
  usersReducer,
  rolesReducer,
  quizReducer,
  inboxReducer,
  taskReducer,
  adminReducer,
  certificateReducer,
  categoriesReducer,
  courseReducer,
  selectedUserReducer,
  userActivityReducer,
  questionBankReducer,
  learningProgramReducer,
  passwordReducer,
  dashboardReducer,
});

export default RootReducers;
