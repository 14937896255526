import React from "react";
import { useRoutes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { useSelector } from "react-redux";
import RTL from "./layouts/full-layout/customizer/RTL";
import ThemeSettings from "./layouts/full-layout/customizer/ThemeSettings";
import Router from "./routes/Router";
import { AuthRouter } from "./routes/Router";
import "react-perfect-scrollbar/dist/css/styles.css";
let authValue = localStorage.getItem("user");
console.log("authValue", authValue);
const App = () => {
  const routing = useRoutes(Router);
  // console.log("Routing", routing);
  const authRouting = useRoutes(AuthRouter);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);
  const token = useSelector((state) => state.usersReducer.token);
  console.log("Token", token, token.length);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {token.length > 1 ? routing : authRouting}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
