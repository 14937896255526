const INIT_STATE = {
    cert: [],
  };

const CertificateReducer = (state = INIT_STATE, action) => {

    switch (action.type) {
      case "UPLOAD_CERTIFICATE":
        return {
          ...state,
          cert: action.cert,
          success: action.success,
          loaded: action.loaded,
          persisted: action.persisted,
        };

      case "ONLOAD_CERTIFICATE":
        console.log("cert in reducer",action.cert)
        return {
          ...state,
          cert: action.cert,
          loaded: action.loaded,
          persisted: action.persisted,
        };
        
        case "UPLOAD_CERTIFICATE_SUCCESS":
        return {
          ...state,
          cert: action.cert,
          success: action.success,
          loaded: action.loaded,
          persisted: action.persisted,
        };
      default:
        return state;
  
    }
  };
  
  export default CertificateReducer;  