/** @format */

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { Api, ApiKey } from "../ApiCredencial";
import { AZ_TASK_MANAGER_API, AZ_TASK_MANAGER_HEADERS } from "../tasks/ApiCred";
import { AZ_ADMIN_API, AZ_ADMIN_HEADERS } from "../users/ApiCred";

export const updateCourse = (course, attr, newval) => (dispatch) => {
  //console.log("updateCourse");
  const c = JSON.parse(JSON.stringify(course));
  c[attr] = newval;

  dispatch({
    type: "UPDATE_COURSE",
    course: c,
    loaded: false,
    persisted: false,
  });
};

export const fetchCourses = (catId) => (dispatch) => {
  const tenant_id = window.sessionStorage.getItem("tenantId");
  if (catId === undefined) {
    catId = "";
  }
  const url = `${AZ_TASK_MANAGER_API}/course?category_id=${catId}&tenant_id=${tenant_id}`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("Response course ", response.data);
      dispatch({
        type: "FETCH_COURSE_SUCCESS",
        courses: response.data,
        loaded: true,
        persisted: false,
      });
    })
    .catch((err) => err);
};
export const fetchCoursesforSequencing = (catId) => (dispatch) => {
  const tenant_id = window.sessionStorage.getItem("tenantId");
  if (catId === undefined) {
    catId = "";
  }
  const url = `${AZ_TASK_MANAGER_API}/course?category_id=${catId}&tenant_id=${tenant_id}`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("Response course ", response.data.body);
      dispatch({
        type: "FETCH_COURSE_SUCCESS_SEQUENCE",
        coursesSequence: response.data,
        // loaded: true,
        // persisted: false,
      });
    })
    .catch((err) => err);
};

export const loadCourse = (id) => (dispatch) => {
  console.log("loadCourse");
  // if (id === -1) {
  // 	dispatch({
  // 		type: "LOAD_COURSE_SUCCESS",
  // 		course: {},
  // 		loaded: true,
  // 		persisted: false,
  // 	});
  // }
  // else {
  const url = `${AZ_TASK_MANAGER_API}/course/${id}`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("course add", response);
      dispatch({
        type: "LOAD_COURSE_SUCCESS",
        course: response.data,
        loaded: false,
        persisted: false,
      });
    })
    .catch((err) => err);
  //	}
};

export const persistCourse = (c) => (dispatch) => {
  console.log(c.time);
  let dt = new Date(c.time);
  let h = dt.getHours(),
    m = dt.getMinutes();
  if (m < 10) {
    m = "0" + m;
  }
  console.log("Date called", dt, h, m);
  let time =
    h > 12
      ? h - 12 + ":" + m + " PM"
      : h < 12 && h > 0
        ? h + ":" + m + " AM"
        : h == 0
          ? 12 + ":" + m + " AM"
          : h + ":" + m + " PM";

  c.time = time;

  if (c.id === undefined) {
    c.id = -1;
  }

  const tenant_id = window.sessionStorage.getItem("tenantId");
  c["tenant_id"] = tenant_id;
  c["active"] = "true";
  const name1 = window.sessionStorage.getItem("firstName");
  const phoneNumber = window.sessionStorage.getItem("phone");
  const createrDetails = name1 + "#" + phoneNumber;
  c["created_by"] = createrDetails;
  console.log("Persist Course", c);
  const url = `${AZ_TASK_MANAGER_API}/course/${c.id}`;
  axios
    .post(url, c, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("Persist Course Response", response);
      dispatch({
        type: "PERSIST_COURSE",
        course: response.data,
        loaded: true,
        persisted: true,
        success: response.data.response,
      });
    })
    .catch((err) => err);
};

export const deleteCourse = (cid) => (dispatch) => {
  const tenant_id = window.sessionStorage.getItem("tenantId");
  const course = {};

  course["active"] = "false";
  course["id"] = cid;
  course["tenant_id"] = tenant_id;
  const url = `${AZ_TASK_MANAGER_API}/course/${cid}`;
  axios
    .post(url, course, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      dispatch({
        type: "DELETE_COURSE",
        course: response.data,
        loaded: true,
        persisted: true,
        success: response.data.response,
      });
    })
    .catch((err) => err);
};

export const updateCourseQuiz =
  (course, currentquiz, time, attr, newval) => (dispatch) => {
    console.log("response for radio", course, currentquiz, time, attr, newval);
    const currentquiz1 = JSON.parse(JSON.stringify(currentquiz));
    currentquiz1[attr] = newval;

    if (course === undefined || course === null) {
      course = {};
    }

    const course1 = JSON.parse(JSON.stringify(course));
    console.log("course quiz", course1, currentquiz1);
    course1["quizzes"][time] = currentquiz1;

    dispatch({
      type: "UPDATE_CURRENT_QUIZ",
      currentquiz: currentquiz1,
      time,
      course: course1,
    });
  };

export const persistCourseQuiz =
  (course, currentquiz, time, method) => (dispatch) => {
    console.log("Data from fe", course, currentquiz, time, method);
    let course1 = JSON.parse(JSON.stringify(course));
    let quizzes = course1["quizzes"];
    if (quizzes === null || quizzes === undefined) {
      course1["quizzes"] = [];
      quizzes = course1["quizzes"];
    }
    console.log("quizzes in action", quizzes.length);
    Object.keys(quizzes).forEach(function (key) {
      if (method === "delete") {
        if (currentquiz.time == key) {
          // Nothing
        } else {
          course1["quizzes"][key] = quizzes[key];
        }
      } else {
        console.log("test1", course1["quizzes"][key], quizzes[key]);
        course1["quizzes"][key] = quizzes[key];
        //course1['quizzes'][time] = currentquiz;
      }
    });
    console.log("delete", method);
    console.log("delete", method !== "delete");
    if (method !== "delete") {
      console.log("test2", currentquiz, time, course1["quizzes"][time]);
      //course1['quizzes'][time] = {currentquiz};
      var time1 = time;
      course1["quizzes"].push({ [time1]: currentquiz });
      //console.log("test3",course1['quizzes'][time])
    }
    console.log("course in action", course1);
    dispatch({
      type: "UPDATE_ALL_QUIZZES",
      course: course1,
      time: time,
    });
  };

export const loadCurrentQuiz = (course, time, index) => (dispatch) => {
  let course1;
  let currentquiz;
  console.log("currentquiz", course, time, index);
  // if(bool == true){
  // 	let time1 =  Object.keys(course.quizzes[time])[0];
  // 	 course1 = JSON.parse(JSON.stringify(course));
  // 	 currentquiz = course1['quizzes'][time][time1]
  // 	course1['quizzes'][time]=currentquiz;
  // }

  // else {
  //console.log("before strigify",course)
  course1 = JSON.parse(JSON.stringify(course));
  //console.log("After strigify",course1)
  if (course1["quizzes"] === undefined || course1["quizzes"] === null) {
    course1["quizzes"] = [];
  }
  if (index !== -1) {
    currentquiz = course1["quizzes"][index][time];
  } else {
    currentquiz = course1["quizzes"][time];
    //console.log("current Quiz",currentquiz)
    if (currentquiz === undefined) {
      currentquiz = {
        time: time,
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: "",
      };
    }
    course1["quizzes"][time] = currentquiz;
    //course1['quizzes'].push = currentquiz
  }
  console.log("currentquiz", currentquiz);
  dispatch({
    type: "UPDATE_CURRENT_QUIZ",
    currentquiz,
    time,
    course: course1,
  });
};

export const loadUserCourse = (id) => (dispatch) => {
  console.log("locadUserCourse", id);
  const user_id = window.sessionStorage.getItem("userId");
  console.log("locadUserCourse", id);
  const url = `${AZ_TASK_MANAGER_API}/inbox/user-course?user_id=${user_id}&course_id=${id}`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("course user", response);
      dispatch({
        type: "LOAD_USER_COURSE_SUCCESS",
        course: response.data,
        loaded: true,
        persisted: false,
      });
    })
    .catch((err) => err);
};

export const updateUserQuiz =
  (course, time, answer, answerArray) => (dispatch) => {
    // 	const answerArray = useSelector((state) =>
    //   state.courseReducer.answerArray
    // );
    const ee = time.time;
    const selected = answer;
    var responses = ee + "#" + selected;
    answerArray.push(responses);
    // let course1 = JSON.parse(JSON.stringify(course));

    // let indexx = "";
    // course1.quizzes.forEach(myFunction)
    // function myFunction(item,index){

    // 	if(Object.keys(item)==time.time){
    // 		indexx = index;
    // 	}
    // }
    // console.log("index",indexx,answer);
    // course1.quizzes[indexx][time.time]["user_answer"] = answer

    dispatch({
      type: "UPDATE_USER_QUIZZES",
      answerArray: answerArray,
      //course: course1
    });
  };
export const persistUserCourse = (c, answerArray) => (dispatch) => {
  // c.quizzes.forEach(myFunction)
  // var answerCount = 0;
  // function myFunction(item,index){
  // 	for(var i=0;i<answerArray.length;i++){
  // 		const [time, answer] = answerArray[i].split('#');
  // 		if(Object.keys(item)==time){
  // 			c.quizzes[index][time]["user_answer"] = answer
  // 			if(c.quizzes[index][time] === answer){
  // 				answerCount = answerCount + 1;
  // 			}
  // 		}
  // 	}
  // }
  //console.log("answercount",answerCount)
  //const earn = earningCal(c.time,answerCount);
  //c["earnings"] = earn;
  //c["submitTime"] = new Date();
  let url;
  const user_id = window.sessionStorage.getItem("userId");
  let dt = new Date(c.submitTime);
  let h = dt.getHours(),
    m = dt.getMinutes();
  if (m < 10) {
    m = "0" + m;
  }
  console.log("Date called", dt, h, m);
  let time =
    h > 12
      ? h - 12 + ":" + m + " PM"
      : h < 12 && h > 0
        ? h + ":" + m + " AM"
        : h == 0
          ? 12 + ":" + m + " AM"
          : h + ":" + m + " PM";

  c.submitTime = time;
  c["status"] = "Done";
  console.log("course after update", c);
  url =
    c.program_id === null
      ? `${AZ_TASK_MANAGER_API}/inbox/user-course?user_id=${user_id}&course_id=${c.id}`
      : `${AZ_ADMIN_API}/api/learningprogram/transactions`;
  console.log("course after update", c, url);
  axios
    .post(url, c, c.program_id === null ? { headers: { ...AZ_TASK_MANAGER_HEADERS } } : { headers: { ...AZ_ADMIN_HEADERS } })
    .then((response) => {
      console.log("Persist Course Response", response);
      dispatch({
        type: "PERSIST_USER_COURSE",
        course: response.data,
        loaded: false,
        persisted: true,
      });
    })
    .catch((err) => err);
};

function earningCal(time0, correct) {
  const d = new Date();
  let earning;
  //c["submitTime"] = d;
  let h1 = d.getHours();
  let m1 = d.getMinutes();
  let time1 = time0;
  const [time, AMPM] = time1.split(" ");

  let [hours, minutes] = time.split(":");
  if (AMPM === "PM" && parseInt(hours) !== 12) {
    hours = parseInt(hours) + 12;
    minutes = parseInt(minutes);
  } else if (AMPM === "AM" && parseInt(hours) === 12) {
    hours = 0;
    minutes = parseInt(minutes);
  } else {
    hours = parseInt(hours);
    minutes = parseInt(minutes);
  }
  let totalminutes1 = h1 * 60 + m1;
  let totalminutes2 = hours * 60 + minutes;
  if (totalminutes1 - totalminutes2 <= 30) {
    earning = correct * 1;
  } else {
    earning = 0;
  }
  return earning;
}

export const nextPageDocument = (currentpage, totalpages) => (dispatch) => {
  console.log("nextPageDocument called");

  let newpage = currentpage + 1;
  newpage = newpage < 0 ? 0 : newpage >= totalpages ? totalpages - 1 : newpage;
  dispatch({
    type: "NEXT_PAGE_PDF",
    currentpage: newpage,
  });
};

export const fetchSearchedCourses = (value) => (dispatch) => {
  const user_id = window.sessionStorage.getItem("userId");
  const url = `${AZ_TASK_MANAGER_API}/activitysearch?user_id=${user_id}&keyword=${value}`;
  console.log("called", url);
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("search Response", response);
      dispatch({
        type: "FETCH_SEARCHED_COURSES",
        searchedCourses: response.data,
        loaded: false,
      });
    })
    .catch((err) => err);
};
export const updateSuccessInCourse = (value) => (dispatch) => {
  dispatch({
    type: "UPDATE_SUCCESS_COURSE",
    success: value,
  });
};
export const updateCourseBlank = () => (dispatch) => {
  dispatch({
    type: "UPDATE_COURSE_BLANK",
    course: {},
  });
};
export const updateSerchedLoaded = () => (dispatch) => {
  dispatch({
    type: "UPDATE_SEARCHED_LOADED",
    searchedCourses: [],
    loaded: true,
  });
};

export const getTags = () => (dispatch) => {
  const tenant_id = window.sessionStorage.getItem("tenantId");
  const url = `${AZ_TASK_MANAGER_API}/course-tag?tenant_id=${tenant_id}`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("TagResponse Response", response);
      dispatch({
        type: "FETCH_TAG_SUCCESS",
        tag: response.data,
        //loaded: false,
      });
    })
    .catch((err) => err);
};
