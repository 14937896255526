/** @format */

const INIT_STATE = {
  password: "",
  otp: "",
  changePassword: false,
  success: "",
};

const PasswordReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "REQUEST_OTP":
      return {
        ...state,
        message: action.message,
      };
    case "SUBMIT_OTP":
      console.log(action);
      return {
        ...state,
        message: action.message,
        changePassword: action.message == "successfull" ? true : false,
      };
    case "RESET_PASSWORD":
      return {
        ...state,
        message: action.message,
        changePassword: action.changePassword,
        success: action.message,
      };
    case "UPDATE_PASSWORD_MESSAGE":
      return {
        ...state,
        changePassword: false,
      };
    case "UPDATE_SUCCESS_LOGIN":
      return {
        ...state,
        success: "",
      };
    default:
      return state;
  }
};

export default PasswordReducer;
