const INIT_STATE = {
  locations: [],
  reportsList: [],
  success: "",
  attributeSettingsList: [],
  attributeSettings: {},
  listLoaded: true,
  loaded: true,
  logoLoaded: false,
  logoData: { name: "", logoUrl: "" },
};

const AdminReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_LOCATIONS":
      const locss = addCityBanglore(action.locations.attr);
      console.log("response Location", locss);
      // const locations = locs.map((loc) => {
      //     return loc.kitchen;
      // });

      return {
        ...state,
        locations: locss,
        loaded: true,
        success: "",
      };
    case "FETCH_ALL_REPORTS_LIST":
      return {
        ...state,
        reportsList: action.reportsList,
        reportData: action.reportData,
        loaded: action.loaded,
      };
    case "UPDATE_REPORTLIST":
      return {
        ...state,
        reportsList: action.reportsList,
      };
    case "FETCH_ATTRIBUTE_SETTINGS_LIST":
      return {
        ...state,
        attributeSettingsList: action.attributeSettingsList,
        listLoaded: action.loaded,
      };
    case "FETCH_ATTRIBUTE_SETTING":
      return {
        ...state,
        attributeSettings: action.attributeSettings,
      };
    case "PERSISIT_ATTRIBUTE_SETTING":
      return {
        ...state,
        success: action.success,
      };
    case "FETCH_TENANT_LOGO":
      return {
        ...state,
        logoData: action.logoData,
        logoLoaded: action.loaded,
      };
    default:
      return state;
  }
};

export default AdminReducer;

function addCityBanglore(locations) {
  let locs = [];
  locations.map((row) => {
    let tempObj = {};
    tempObj["kitchen"] = row.value;
    tempObj["city"] = "Banglore";
    locs.push(tempObj);
  });

  return locs;
}
