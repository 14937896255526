/** @format */

import axios from "axios";
import { Api, ApiKey } from "../ApiCredencial";
import { AZ_TASK_MANAGER_API, AZ_TASK_MANAGER_HEADERS } from "../tasks/ApiCred";
import { AZ_ADMIN_API, AZ_ADMIN_HEADERS } from "../users/ApiCred";

export const updateQuiz = (quiz, attr, newval) => (dispatch) => {
  console.log("updateQuiz", quiz);

  // const q = {
  //   id: quiz.id,
  //   name: quiz.name,
  //   desc: quiz.desc,
  //   interval: quiz.interval,
  //   startDate: quiz.startDate,
  //   time: quiz.time,
  //   day: quiz.day,
  //   date: quiz.date,
  //   endDate: quiz.endDate,
  //   quesperinterval: quiz.quesperinterval,
  //   //questionsid: quiz.questionsid,

  //   selectusers: quiz.selectusers,
  //   questions: quiz.questions,
  //   active: quiz.active,
  // };
  let q = JSON.parse(JSON.stringify(quiz));

  q[attr] = newval;

  dispatch({
    type: "UPDATE_QUIZ",
    quiz: q,
    loaded: true,
    persisted: false,
  });
};

export const fetchQuizzes = () => (dispatch) => {
  const tenant = window.sessionStorage.getItem("tenantId");
  const academy = window.sessionStorage.getItem("academyQuiz");
  const url = `${AZ_TASK_MANAGER_API}/quiz?tenant_id=${tenant}&academy=${academy}`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("Response quiz ", response);
      dispatch({
        type: "FETCH_QUIZZES_SUCCESS",
        quizzes: response.data,
        loaded: false,
        persisted: false,
      });
    })
    .catch((err) => err);
};

export const quizAnalysisDetails =
  (quiz, userDetails, timeRemain) => (dispatch) => {
    dispatch({
      type: "ANALYSIS_DETAILS",
      quiz: quiz,
      userDetails: userDetails,
      timeRemain: timeRemain,
    });
  };

export const loadQuiz = (id) => (dispatch) => {
  //const url = `https://api.kouzinafoodtech.com/kim/`
  const academy = window.sessionStorage.getItem("academyQuiz");
  const url = `${AZ_TASK_MANAGER_API}/quiz/${id}?academy=${academy}`;
  console.log("URL", url);
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("LoadQuiz Response", response);
      dispatch({
        type: "LOAD_QUIZ_SUCCESS",
        quiz: response.data,
        loaded: true,
        persisted: false,
      });

      const quiz1 = response.data;

      console.log("Quiz1", quiz1);

      // if(quiz1 && quiz1.id && quiz1.id !== -1){
      // 	console.log("Quiz2", quiz1.selectusers);
      // 	//const selectedUser = quiz1.selectedusers.split(',');
      // 	console.log("Quiz3");
      // 	dispatch({
      // 		type: "UPDATE_SELECTED",
      // 		selectedUser:{"selectedusers":quiz1.selectusers},
      // 		loaded: true,
      // 		persisted: false,
      // 	});
      // }
    })
    .catch((err) => err);
};

export const persistQuiz = (q) => (dispatch) => {
  console.log("updateQuiz");
  console.log(q);
  let dt = new Date(q.time);
  let h = dt.getHours(),
    m = dt.getMinutes();
  if (m < 10) {
    m = "0" + m;
  }
  console.log("Date called", dt, h, m);
  let time =
    h > 12
      ? h - 12 + ":" + m + " PM"
      : h < 12 && h > 0
        ? h + ":" + m + " AM"
        : h == 0
          ? 12 + ":" + m + " AM"
          : h + ":" + m + " PM";
  q["time"] = time;
  const tenant_id = window.sessionStorage.getItem("tenantId");
  if (q.id === undefined) {
    q.id = -1;
  }
  q["active"] = "true";
  q["tenant_id"] = tenant_id;
  const name1 = window.sessionStorage.getItem("firstName");
  const phoneNumber = window.sessionStorage.getItem("phone");
  const createrDetails = name1 + "#" + phoneNumber;
  q["updated_by"] = createrDetails;
  console.log("after update", q);
  const url = `${AZ_TASK_MANAGER_API}/quiz/${q.id}`;
  axios
    .post(url, q, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("Quiz Response", response);
      dispatch({
        type: "UPDATE_QUIZ",
        quiz: response.data,
        loaded: false,
        persisted: true,
        success: response.data,
      });
    })
    .catch((err) => err);
};

export const uploadQuestions = (result) => (dispatch) => {
  console.log("Result", result);
  const url = `${AZ_ADMIN_API}/api/questions/upload`;
  axios
    .post(url, {
      data: result,
    }, { headers: { ...AZ_ADMIN_HEADERS } })
    .then((response) => {
      console.log("check", response.data.bank_id);
      dispatch({
        type: "QUESTIONS_UPLOADED",
        questionbank: response.data,
        loaded: true,
      });
    })
    .catch((err) => err);
};

export const fetchQuiz = (itemid, itemtype, date) => (dispatch) => {
  const user_id = window.sessionStorage.getItem("userId");
  console.log("userID", user_id);
  const url = `${AZ_TASK_MANAGER_API}/inbox/user-quiz?user_id=${user_id}&quiz_id=${itemid}&date=${date}`;
  axios
    .get(url, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      let result = response.data.body.quizDetails;
      console.log("result at start", response.data);
      if (result != undefined) {
        result.map((ele) => {
          console.log("Element", ele);
          console.log("Element", ele.correct_answer.indexOf("A"));
          if (ele.correct_answer.indexOf("A") > -1) {
            console.log("A", ele.correct_answer);
            ele.answer = ele["choice"][0];
          }
          if (ele.correct_answer.indexOf("B") > -1) {
            ele.answer = ele["choice"][1];
          }
          if (ele.correct_answer.indexOf("C") > -1) {
            ele.answer = ele["choice"][2];
          }
          if (ele.correct_answer.indexOf("D") > -1) {
            ele.answer = ele["choice"][3];
          }
        });
      }
      result.ques_id = response.data.ques_id;
      console.log("Fetch Quiz", result);
      dispatch({
        type: "FETCH_USER_QUIZ",
        quesdata: result,
        userquiz: response.data,
        currentques: 0,
        totalcorrect: 0,
        analyseQuiz: date !== "" ? response.data : "",
        attemptQuizSuccess:
          date !== "" ? (response.data ? "success" : "") : "",
      });
    })
    .catch((err) => err);
};

export const fetchQuestions = () => (dispatch) => {
  const url = `${Api}/questions`;
  axios
    .get(url)
    .then((response) => {
      dispatch({
        type: "FETCH_QUESTIONS",
        quesdata: response.data.body,
        currentques: 0,
        totalcorrect: 0,
        attemptQuizSuccess: "",
      });
    })
    .catch((err) => err);
};
export const onDownload = (id) => (dispatch) => {
  const url = `${Api}/questions/download/${id}`;
  axios
    .get(url)
    .then((response) => {
      console.log("data after call of api", response);
      dispatch({
        type: "DOWNLOAD_QUESTIONS",
        downquesdata: response.body,
        loaded: true,
        persisted: true,
      });
    })
    .catch((err) => err);
};
export const onDownloadtemplate = () => (dispatch) => {
  //const url = `${Api}/questions/download`;
  // axios
  //   .get(url)
  //   .then((response) => {
  //     console.log("data after call of template", response);
  const response = [
    {
      Sno: 1,
      Question: "Who is the CEO of Kouzina Food tech ?",
      A: "Gautam Balijepalli",
      B: "Sumit Gupta",
      C: "Rohan Rao",
      D: "Mahesh Madiyala",
      "Correct Answer": "A",
      "Answer Explanation": "Please Redo Bootcamp Training",
    },
    {
      Sno: 2,
      Question: "Number of Kouzina Own Kitchens?",
      A: "21",
      B: "26",
      C: "40",
      D: "15",
      "Correct Answer": "A",
      "Answer Explanation":
        "There are company owned kitchens and partner kitchens",
    },
  ];
  dispatch({
    type: "DOWNLOAD_TEMPLATE",
    downquesdata: response,
    loaded: true,
    persisted: true,
  });
  // })
  // .catch((err) => err);
};
export const updateQues =
  (userquiz, quesdata, currques, answer, totalcorrect) => (dispatch) => {
    const quesdaata1 = JSON.parse(JSON.stringify(quesdata));
    quesdaata1[currques]["user_answer"] = answer;
    console.log("updateQuest called", quesdaata1);
    if (quesdaata1[currques]["answer"] === answer) {
      totalcorrect = totalcorrect + 1;
    }
    dispatch({
      type: "FETCH_USER_QUIZ",
      userquiz,
      quesdata: quesdaata1,
      currentques: currques,
      totalcorrect,
      analyseQuiz: "",
      attemptQuizSuccess: "",
    });
  };

export const updateQuesAcademy = (quiz, currentques, answer) => (dispatch) => {
  const q = JSON.parse(JSON.stringify(quiz));
  q["quizDetails"][currentques]["user_answer"] = answer;
  console.log(q);

  dispatch({
    type: "UPDATE_ACADEMY_QUIZ_USER",
    quiz: q,
  });
};

export const nextQues =
  (userquiz, quesdata, currques, totalcorrect) => (dispatch) => {
    dispatch({
      type: "FETCH_USER_QUIZ",
      userquiz,
      quesdata,
      currentques: currques + 1,
      totalcorrect,
      analyseQuiz: "",
      attemptQuizSuccess: "",
    });
  };
export const persistAttemptAcademy =
  (quiz, userDetails, remainingTime) => (dispatch) => {
    let dt = new Date(userDetails.submitTime);
    let h = dt.getHours(),
      m = dt.getMinutes();
    if (m < 10) {
      m = "0" + m;
    }
    console.log("Date called", dt, h, m);
    let time =
      h > 12
        ? h - 12 + ":" + m + " PM"
        : h < 12 && h > 0
          ? h + ":" + m + " AM"
          : h == 0
            ? 12 + ":" + m + " AM"
            : h + ":" + m + " PM";

    userDetails.submitTime = time;
    const user_id = window.sessionStorage.getItem("userId");
    userDetails["status"] = "Done";
    userDetails["quiz_id"] = userDetails.id;
    userDetails["quizDetails"] = quiz.quizDetails;
    userDetails["remainingTime"] = remainingTime;
    userDetails["user_id"] = user_id;
    console.log("userDetails", userDetails);
    const url = `${AZ_TASK_MANAGER_API}/inbox/user-quiz`;
    axios
      .post(url, userDetails, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
      .then((response) => {
        dispatch({
          type: "FETCH_QUESTIONS",
          questions: response.data,
          currentques: 0,
          totalcorrect: 0,
          attemptQuizSuccess: response.data.response,
        });
      })
      .catch((err) => err);
  };
export const persistAttempt =
  (userquiz, questions, userDetails) => (dispatch) => {
    let dt = new Date(userDetails.submitTime);
    let h = dt.getHours(),
      m = dt.getMinutes();
    if (m < 10) {
      m = "0" + m;
    }
    console.log("Date called", dt, h, m);
    let time =
      h > 12
        ? h - 12 + ":" + m + " PM"
        : h < 12 && h > 0
          ? h + ":" + m + " AM"
          : h == 0
            ? 12 + ":" + m + " AM"
            : h + ":" + m + " PM";

    userDetails.submitTime = time;
    userDetails["status"] = "Done";
    const name1 = window.sessionStorage.getItem("firstName");
    const phoneNumber = window.sessionStorage.getItem("phone");
    const createrDetails = name1 + "#" + phoneNumber;
    userDetails["submitBy"] = createrDetails;
    userDetails["quizDetails"] = questions;
    const user_id = window.sessionStorage.getItem("userId");
    userDetails["user_id"] = user_id;
    userDetails["bank_id"] = userquiz.bank_id;
    userDetails["quiz_id"] = userDetails.id;
    userDetails["ques_id"] = userquiz.ques_id;
    //userDetails["ques_id"] = console.log("userquiz", userquiz); ?user_id=${user_id}&quiz_id=${userDetails.id}
    console.log("userDetails", userDetails);
    const url = `${AZ_TASK_MANAGER_API}/inbox/user-quiz`;
    //const url = "";
    axios
      .post(url, userDetails, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
      .then((response) => {
        dispatch({
          type: "FETCH_QUESTIONS",
          questions: response.data,
          currentques: 0,
          totalcorrect: 0,
          attemptQuizSuccess: "",
        });
      })
      .catch((err) => err);
  };

export const deleteQuiz = (quiz) => (dispatch) => {
  // const [isactive,setActive]=useSate(false);
  // setActive(curentId)
  const name1 = window.sessionStorage.getItem("firstName");
  const phoneNumber = window.sessionStorage.getItem("phone");
  const createrDetails = name1 + "#" + phoneNumber;
  console.log("Delete Quiz Called");
  console.log(quiz);
  quiz["active"] = "false";
  quiz["updated_by"] = createrDetails;
  console.log(quiz);
  const url = `${AZ_TASK_MANAGER_API}/quiz/${quiz.id}`;
  axios
    .post(url, quiz, { headers: { ...AZ_TASK_MANAGER_HEADERS } })
    .then((response) => {
      console.log("Delete", response);
      dispatch({
        type: "UPDATE_QUIZ",
        quiz: response.data,
        loaded: true,
        persisted: true,
        success: response.data.response,
      });
    })
    .catch((err) => err);
};

export const persistUserQuiz = () => (dispatch) => { };
export const updateSuccessInQuiz = (value) => (dispatch) => {
  dispatch({
    type: "UPDATE_SUCCESS_IN_QUIZ",
    success: value,
  });
};

export const updateQuizBlank = () => (dispatch) => {
  dispatch({
    type: "UPDATE_QUIZ_BLANK",
    quiz: {},
  });
};

export const updateAcademyQuiz = (quiz, attr, value) => (dispatch) => {
  let q1 = JSON.parse(JSON.stringify(quiz));
  q1[attr] = value;
  console.log("q1", quiz, q1, attr, value);
  dispatch({
    type: "UPDATE_ACADEMY_QUIZ",
    quiz: q1,
  });
};
