import { React } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Tooltip,
  DialogContent,
  DialogContentText,
  Box,
  CardMedia,
  Button,
} from "@material-ui/core";
import CrossIcon from "@material-ui/icons/CancelOutlined";
//import { Button } from "antd";
const EnlargeImageViewMultiple = (props) => {
  const {
    openViewEnlargedImage,
    handleCloseEnlargeddView,
    largeImage,
    totalImage,
    current,
    handleNext,
    handlePrevious,
  } = props;

  // console.log("total,current", totalImage, current);
  return (
    <Dialog
      open={openViewEnlargedImage}
      onClose={(e) => {
        handleCloseEnlargeddView();
      }}
    >
      <DialogTitle>
        <DialogActions>
          <Typography variant="h6" fontWeight="600">
            <Tooltip
              title="Close"
              describeChild="true"
              onClick={(e) => {
                handleCloseEnlargeddView();
              }}
            >
              <CrossIcon variant="outlined" />
            </Tooltip>
          </Typography>
        </DialogActions>
        <Typography variant="bold">
          Page No {current}/{totalImage}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {/* <Card> */}
          {/* <CardActions>X</CardActions> */}

          <Box>
            <CardMedia
              // sx={{ width: 3, height: 300 }}
              component="img"
              controls={false}
              src={largeImage}
            />
          </Box>
          {/* </Card> */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {current !== 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              handlePrevious();
            }}
          >
            Previous
          </Button>
        ) : (
          ""
        )}
        {current !== totalImage ? (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleNext();
            }}
          >
            Next
          </Button>
        ) : (
          ""
        )}
        {current === totalImage ? (
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              handleCloseEnlargeddView();
            }}
          >
            Done
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EnlargeImageViewMultiple;
