/**@format */

import { getTypeByValue } from "@material-ui/utils/integerPropType";
import { act } from "@testing-library/react";
import { FETCH_USERS_ATTR_SUCCESS } from "../constants";

const INIT_STATE = {
  statusData: [],
  loaded: true,
  userattrs: [],
  filterAtrs: [],
  locationLoaded: true,
  userLocation: [],
};

const UserActivityReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_EARNING_ADMIN":
      let data = changeData(action.earning);
      console.log("data", data);
      return {
        ...state,
        earning: data,
        loaded: true,
        persisted: action.persisted,
      };
    case "FETCH_STATUS_ADMIN":
      return {
        ...state,
        statusData: action.statusData,
        loaded: action.loaded,
        message: action.message,
      };
    case "UPDATE_STATUS_DATA":
      return {
        ...state,
        statusData: action.statusData,
      };
    case "FETCH_USER_LOCATION_DETAILS":
      return {
        ...state,
        userLocation: action.userLocation,
        locationLoaded: action.locationLoaded,
      };
    case "UPDATE_LOCATION_LOADED":
      return {
        ...state,
        locationLoaded: action.locationLoaded,
      };
    case "UPDATE_USER_LOCATION_ACTIVITY":
      console.log("user location", action);
      return {
        ...state,
        userLocation: action.userLocation,
      };
    case FETCH_USERS_ATTR_SUCCESS:
      return {
        ...state,
        userattrs: action.userattrs,
      };
    case "UPDATE_LOADED_USER_ACTIVITY":
      return {
        ...state,
        loaded: action.loaded,
      };
    case "FETCH_ANALYTICS_ADMIN":
      console.log("test data in reducer", action.rawEarning, action.dArray);
      let indv = individual(action.rawEarning, action.dArray);
      let finalAttributes = formatAttribute(
        state.userattrs,
        action.rawEarning,
        action.dArray
      );
      const filterAtrs = attributefilter(state.userattrs);
      console.log("final Attribute", finalAttributes);
      // let dept = attributeWise("Department", action.rawEarning, action.dArray);
      // let location = attributeWise(
      //   "Location",
      //   action.rawEarning,
      //   action.dArray
      // );
      // let Desig = attributeWise(
      //   "Designation",
      //   action.rawEarning,
      //   action.dArray
      // );
      //console.log(dept);
      return {
        ...state,
        individual: indv,
        // Department: dept,
        // Location: location,
        // Designation: Desig,
        filterAtrs: filterAtrs,
        finalAttributes: finalAttributes,
        Date: action.dArray,
        loaded: true,
        persisted: action.persisted,
      };
    default:
      return state;
  }
};

export default UserActivityReducer;

function changeData(rawData) {
  let finalData = [];

  rawData.map((data) => {
    let personalDetails = {};

    personalDetails.id = data.id;
    personalDetails.first_name = data.first_name;
    personalDetails.last_name = data.last_name;
    personalDetails.phone_num = data.phone_num;
    personalDetails.activity = changeDataFormat(data.activity);
    personalDetails.attribute = changeAttribute(data.attribute);
    personalDetails.totalEarning =
      personalDetails.activity[0].totalEarning +
      personalDetails.activity[1].totalEarning +
      personalDetails.activity[2].totalEarning;

    finalData.push(personalDetails);
  });

  return finalData;
}

function changeDataFormat(data) {
  //console.log("console 1", data);
  let course = {};
  let quiz = {};
  let task = {};
  let courseTotal = 0;
  let taskTotal = 0;
  let quizTotal = 0;
  let courseDetails = [];
  let taskDetails = [];
  let quizDetails = [];
  let courseCount = 0;
  let quizCount = 0;
  let taskCount = 0;
  data.map((row) => {
    if (row.type === "Course" || row.type === "course") {
      courseCount = courseCount + 1;
      courseTotal = courseTotal + Number(row.earning);
      courseDetails.push(row);
    }
    if (row.type === "Quiz" || row.type === "quiz") {
      quizCount = quizCount + 1;
      quizTotal = quizTotal + Number(row.earning);
      quizDetails.push(row);
    }
    if (row.type === "Task" || row.type === "task") {
      taskCount = taskCount + 1;
      task["type"] = "Task";
      taskTotal = taskTotal + Number(row.earning);
      taskDetails.push(row);
    }
  });

  course["type"] = "Course";
  course["totalEarning"] = courseTotal;
  course["totalCount"] = courseCount;
  course["details"] = courseDetails;
  quiz["type"] = "Quiz";
  quiz["totalEarning"] = quizTotal;
  quiz["totalCount"] = quizCount;
  quiz["details"] = quizDetails;
  task["type"] = "Task";
  task["totalEarning"] = taskTotal;
  task["totalCount"] = taskCount;
  task["details"] = taskDetails;
  //console.log("after update", course, task, quiz);
  var finalArray = new Array();
  if (course !== undefined) {
    finalArray.push(course);
  }
  if (quiz !== undefined) {
    finalArray.push(quiz);
  }
  if (task !== undefined) {
    finalArray.push(task);
  }
  // console.log("finalArray", finalArray);
  return finalArray;
}

function changeAttribute(data) {
  let details = {};
  data.map((row) => {
    details[row.attr] = row.value;
  });
  return details;
}

function individual(rawData, dates) {
  //console.log(rawData, dates);
  let filteredData = [];
  rawData.map((row) => {
    let PD = {};
    PD.first_name = row.first_name;
    PD.name = row.first_name;
    PD.last_name = row.last_name;
    PD.phone_num = row.phone_num;
    PD.id = row.id;
    //console.log("Data for", row.first_name);
    let tot = totalEarning(row.activity);
    //console.log("Data for 2", row.first_name);
    PD.total = tot.totalEarning;
    PD.quizTotal = tot.quizTotal;
    PD.courseTotal = tot.courseTotal;
    PD.taskTotal = tot.taskTotal;
    PD.attribute = row.attribute;

    // PD.Department = PD.attribute.Department
    //   ? PD.attribute.Department
    //   : "Not Assign";
    // PD.Location = PD.attribute.Location ? PD.attribute.Location : "Not Assign";
    // PD.Designation = PD.attribute.Designation
    //   ? PD.attribute.Designation
    //   : "Not Assign";
    row.attribute.map((row1) => {
      PD[row1.attr] = row1.value;
    });

    PD.activity = personalEarning(row.activity, dates);

    filteredData.push(PD);
  });
  //console.log(filteredData);
  return filteredData;
}

function totalEarning(data) {
  //console.log("data in total earning", data);
  let earning = {};
  let courseTotal = 0;
  let taskTotal = 0;
  let quizTotal = 0;
  let total = 0;
  data.map((row) => {
    if (row.type === "Course" || row.type === "course") {
      courseTotal = courseTotal + Number(row.earning);
      total = total + Number(row.earning);
      //console.log("total earning in course", courseTotal, total);
    }
    if (row.type === "Quiz" || row.type === "quiz") {
      quizTotal = quizTotal + Number(row.earning);
      total = total + Number(row.earning);
      //console.log("total earning in quiz", quizTotal, total);
    }
    if (row.type === "Task" || row.type === "task") {
      taskTotal = taskTotal + Number(row.earning);
      total = total + Number(row.earning);
      //console.log("total earning in task", taskTotal, total);
    }
  });
  earning.totalEarning = total;
  earning.quizTotal = quizTotal;
  earning.courseTotal = courseTotal;
  earning.taskTotal = taskTotal;
  return earning;
}

function personalEarning(data, dates) {
  let activity = [];
  dates.map((row) => {
    let dateData = {};
    let quiz = 0;
    let task = 0;
    let course = 0;
    data.map((check) => {
      //console.log("if Called",(row == check.submission_date),row,check.submission_date)
      if (row == check.submission_date) {
        //console.log("if Called");
        if (check.type == "Quiz" || check.type == "quiz") {
          quiz = quiz + Number(check.earning);
        } else if (check.type == "Course" || check.type == "course") {
          course = course + Number(check.earning);
        } else {
          task = task + Number(check.earning);
        }
      }
    });
    dateData.date = row;
    dateData.quiz = quiz;
    dateData.task = task;
    dateData.course = course;
    dateData.total = quiz + course + task;

    activity.push(dateData);
  });
  //console.log("formated data activity", activity);
  return activity;
}

function attributeWise(type, rawData, dates) {
  let data = individual(rawData, dates);
  let unique;
  if (data) {
    //console.log("if called");
    unique = uniqueType(data, type);
  }
  let finalArray = finalFunction(data, type, unique, dates);

  return finalArray;
}

function uniqueType(data, type) {
  let types = [];
  //console.log(data);
  data.map((row) => {
    types.push(row[type]);
  });
  let finalArray = [...new Set(types)];

  return finalArray;
}

function finalFunction(data, type, value, dates) {
  let finalArray = [];
  value.map((row) => {
    let PD = {};
    let personal = [];
    let totalEarning = 0;
    let QTotal = 0;
    let CTotal = 0;
    let TTotal = 0;
    PD[type] = row;
    PD.name = row;

    data.map((ind) => {
      //console.log(ind[type], row);
      if (ind[type] == row) {
        let pDetails = {};
        totalEarning = totalEarning + ind.total;
        QTotal = QTotal + ind.quizTotal;
        CTotal = CTotal + ind.courseTotal;
        TTotal = TTotal + ind.taskTotal;
        pDetails.first_name = ind.first_name;
        pDetails.last_name = ind.last_name;
        pDetails.phone_num = ind.phone_num;
        ind.attribute.map((r1) => {
          pDetails[r1.attr] = r1.value;
        });
        pDetails.total = ind.total;
        pDetails.activity = ind.activity;
        personal.push(pDetails);
      }
    });
    PD.personal = personal;
    PD.total = totalEarning;
    PD.quizTotal = QTotal;
    PD.courseTotal = CTotal;
    PD.taskTotal = TTotal;
    PD.activity = changeActivity(personal, dates);
    finalArray.push(PD);
  });
  //console.log(finalArray);
  return finalArray;
}

function changeActivity(data, dates) {
  let dates2 = typeof dates == "string" ? dates.split(",") : dates;
  let finalArray = [];
  dates2.map((row) => {
    let dateData = {};
    let total = 0;
    let qTotal = 0;
    let cTotal = 0;
    let tTotal = 0;
    data.map((check) => {
      check.activity.map((ind) => {
        if (ind.date == row) {
          total = total + ind.total;
          qTotal = qTotal + ind.quiz;
          cTotal = cTotal + ind.course;
          tTotal = tTotal + ind.task;
        }
      });
    });
    dateData.total = total;
    dateData.quiz = qTotal;
    dateData.course = cTotal;
    dateData.task = tTotal;
    dateData.date = row;
    finalArray.push(dateData);
  });
  return finalArray;
}

function formatAttribute(attr, dataArray, dArray) {
  let finalObj = {};
  attr.map((row) => {
    finalObj[row.name] = attributeWise(row.name, dataArray, dArray);
  });
  return finalObj;
}

function attributefilter(attrs) {
  let arr = [];
  arr.push({ name: "Individual" });
  attrs.map((row) => {
    let obj = {};
    obj.name = row.name;
    arr.push(obj);
    console.log("test", arr, obj);
  });
  return arr;
}
