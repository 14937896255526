import { element } from "prop-types";
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

const academy = window.sessionStorage.getItem("academyQuiz");

console.log("AcademyQuiz", academy);
/* ***Layouts**** */
const FullLayout = lazy(() => import("../layouts/full-layout/FullLayout"));
const BlankLayout = lazy(() => import("../layouts/blank-layout/BlankLayout"));
/* ***End Layouts**** */

const Error = lazy(() => import("../views/authentication/Error"));
const Login = lazy(() => import("../views/authentication/Login"));
// const ForgotPassword = lazy(() =>
//   import("../views/authentication/ForgotPassword")
// );

/* ****Pages***** */
const Dashboard = lazy(() => import("../views/dashboards/Dashboard"));
const Dashboard1 = lazy(() => import("../views/dashboards/Dashboard1"));

/* ****Apps***** */
const UserLists = lazy(() => import("../views/apps/users/UserLists"));
const UserEdit = lazy(() => import("../views/apps/users/UserEdit"));
const BulkUserAdd = lazy(() => import("../views/apps/users/BulkUserAdd"));
const UserAttrLists = lazy(() => import("../views/apps/users/UserAttrLists"));
const UserAttrEdit = lazy(() => import("../views/apps/users/UserAttrEdit"));
const RoleLists = lazy(() => import("../views/apps/roles/RoleLists"));
// const RoleEdit = lazy(() => import("../views/apps/roles/RoleEdit"));
const QuizLists = lazy(() => import("../views/microlearn/QuizLists"));
const QuizConfigWizard = lazy(() =>
  import("../views/microlearn/QuizConfigWizard")
);
const Inbox = lazy(() => import("../views/apps/inbox/Inbox"));
const Task = lazy(() => import("../views/apps/inbox/Task"));
const Quiz = lazy(() => import("../views/apps/inbox/Quiz"));
const Course = lazy(() => import("../views/apps/inbox/Course"));
const TaskLists = lazy(() => import("../views/taskmanager/TaskLists"));
const TaskEdit = lazy(() => import("../views/taskmanager/TaskEdit"));
const CompleteLists = lazy(() => import("../views/apps/inbox/CompleteLists"));
const Certificate = lazy(() =>
  import("../views/certificate/CreateCertificate")
);
const MainCatLists = lazy(() =>
  import("../views/macrolearn/main/MainCatLists")
);
const MainCatEdit = lazy(() => import("../views/macrolearn/main/MainCatEdit"));
const SubCatLists = lazy(() => import("../views/macrolearn/sub/SubCatLists"));
const SubCatEdit = lazy(() => import("../views/macrolearn/sub/SubCatEdit"));
const CourseLists = lazy(() =>
  import("../views/macrolearn/courses/CourseLists")
);
const CourseEdit = lazy(() => import("../views/macrolearn/courses/CourseEdit"));
const EarningList = lazy(() =>
  import("../views/useractivity/earning/EarningList")
);
const Analytics = lazy(() =>
  import("../views/useractivity/analytics/Analytics")
);
const QuesBank = lazy(() => import("../views/questionBank/ViewQuestionList"));
const QuesAdd = lazy(() => import("../views/questionBank/QuestionUpload"));
const TestAdd = lazy(() => import("../views/questionBank/TestUpload"));
const Status = lazy(() => import("../views/useractivity/status/Status"));
const UserHelpDesk = lazy(() => import("../views/apps/users/UserHelpDesk"));
const ForgotPassword = lazy(() =>
  import("../views/authentication/ForgotPassword")
);

const ProgramLists = lazy(() =>
  import("../views/learningProgram/program/ProgramLists")
);
const ProgramEdit = lazy(() =>
  import("../views/learningProgram/program/ProgramEdit")
);
const AcademyList = lazy(() =>
  import("../views/learningProgram/academy/AcademyList")
);
const AcademyEdit = lazy(() =>
  import("../views/learningProgram/academy/AcademyEdit")
);
const Reports = lazy(() => import("../views/apps/reports/Reports"));
const TenantManagement = lazy(() =>
  import("../views/tenantManagement/TenantManagement")
);
const TenantEdit = lazy(() => import("../views/tenantManagement/TenantEdit"));
const TenantUser = lazy(() =>
  import("../views/tenantManagement/tenantComponent/TenantUser")
);
const AttributeSettings = lazy(() =>
  import("../views/apps/settings/AttributeSettings")
);
const ShrinkVideo = lazy(() => import("../views/videoShrink/ShrinkVideo"));

const SopView = lazy(() => import("../views/sopManagement/SopView"));
const SopAdd = lazy(() => import("../views/sopManagement/SopEdit"));
const AdhocTask = lazy(() => import("../views/apps/inbox/AdhocTasks"));
const TenantSetting = lazy(() =>
  import("../views/tenantManagement/TenantSettings")
);
const TenantSettingUpdate = lazy(() =>
  import("../views/tenantManagement/tenantComponent/TenantSettingUpdate")
);
const UserLocation = lazy(() =>
  import("../views/apps/userLocationOnMap/UserGeoLocationList")
);
const QuizSummary = lazy(() => import("../views/apps/inbox/QuizAnalysis"));

const AcademyQuizEdit = lazy(() =>
  import("../views/microlearn/AcademyQuizEdit")
);
/* ****Routes***** */
let test = localStorage.getItem("user");
//test = JSON.parse(test);
console.log("isAuth", test);
const Router = [
  {
    path: "/",
    element: <FullLayout />,

    children: [
      { path: "/", element: <Navigate to="/auth/login" /> },
      {
        path: "/dashboards/starter",
        exact: true,
        element: <Dashboard />,
      },
      { path: "/inbox", element: <Inbox /> },
      { path: "/inbox/completed", element: <CompleteLists /> },
      { path: "/inbox/task", element: <Task /> },
      { path: "/inbox/quiz", element: <Quiz /> },
      { path: "/inbox/course", element: <Course /> },
      { path: "/inbox/Adhoc", element: <AdhocTask /> },
      { path: "inbox/quizsummary", element: <QuizSummary /> },
      {
        path: "/users/lists",
        exact: true,
        element: <UserLists />,
      },
      { path: "/users/edit", exact: true, element: <UserEdit /> },
      { path: "/users/bulkAdd", exact: true, element: <BulkUserAdd /> },
      { path: "/users/attrlists", exact: true, element: <UserAttrLists /> },
      { path: "/users/attredit", exact: true, element: <UserAttrEdit /> },
      { path: "/roles/lists", exact: true, element: <RoleLists /> },
      // { path: "/roles/edit", exact: true, element: <RoleEdit /> },
      { path: "/microlearn/quizlists", element: <QuizLists /> },
      {
        path: "/microlearn/addquiz",
        element: <QuizConfigWizard />,
      },
      { path: "/taskmanager/tasklists", element: <TaskLists /> },
      { path: "/taskmanager/task", element: <TaskEdit /> },
      { path: "/earnings", element: <Dashboard1 /> },
      { path: "*", element: <Navigate to="/auth/login" /> },
      { path: "/certificate/createcertificate", element: <Certificate /> },
      { path: "/macrolearn/main", element: <MainCatLists /> },
      { path: "/macrolearn/addMainCat", element: <MainCatEdit /> },
      { path: "/macrolearn/sub", element: <SubCatLists /> },
      { path: "/macrolearn/addSubCat", element: <SubCatEdit /> },
      { path: "/macrolearn/courses", element: <CourseLists /> },
      { path: "/macrolearn/addCourse", element: <CourseEdit /> },
      { path: "/activity/earnings", element: <EarningList /> },
      { path: "/activity/analytics", element: <Analytics /> },
      { path: "/quesBank/list", element: <QuesBank /> },
      { path: "/quesBank/addBank", element: <QuesAdd /> },
      { path: "/quesBank/test", element: <TestAdd /> },
      { path: "/activity/status", element: <Status /> },
      { path: "/userhelpdesk/list", element: <UserHelpDesk /> },
      { path: "/learningprogram/academylists", element: <AcademyList /> },
      { path: "/learningprogram/academyedits", element: <AcademyEdit /> },
      { path: "/learningprogram/programlists", element: <ProgramLists /> },
      { path: "/learningprogram/programedits", element: <ProgramEdit /> },
      { path: "/reports", element: <Reports /> },
      { path: "/tenants/lists", element: <TenantManagement /> },
      { path: "/tenants/Edit", element: <TenantEdit /> },
      { path: "/tenants/User", element: <TenantUser /> },
      { path: "/setting/attribute", element: <AttributeSettings /> },
      { path: "/setting/shrinkvideo", element: <ShrinkVideo /> },
      { path: "/sopBank/view", element: <SopView /> },
      { path: "/sopBank/update", element: <SopAdd /> },
      { path: "/tenants/settings", element: <TenantSetting /> },
      { path: "/tenants/setting/update", element: <TenantSettingUpdate /> },
      { path: "/activity/location", element: <UserLocation /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/auth/login", element: <Login /> },
      { path: "/auth/resetPassword", element: <ForgotPassword /> },
    ],
  },
];
export const AuthRouter = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      //{ path: "/auth/resetPassword", element: <ForgotPassword /> },
      { path: "/", element: <Navigate to="/auth/login" /> },
      { path: "*", element: <Navigate to="/auth/login" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: "/auth/resetPassword", element: <ForgotPassword /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
      { path: "/auth/login", element: <Login /> },
      // { path: "/forgotpassword", element: <ForgotPassword /> },
    ],
  },
];

export default Router;
