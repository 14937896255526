import { act } from "react-dom/test-utils";

const INIT_STATE = {
  qBMainCat: {},
  qBMainCats: [],
  qBSubCat: {},
  qBSubCats: [],
  oneByOne: {},
  aiQuestionAttribute: {},
  aiLoaded: true,
  aiQuestions: [],
  aiQuestionsSuccess: "",
};

const QuestionBankReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "UPDATE_QUESTIONBANK_MAINCAT":
      return {
        ...state,
        qBMainCat: action.qBMainCat,
      };
    case "DELETE_QB_ID":
      return {
        ...state,
        QuestionBank: action.QuestionBank,
      };
    case "UPDATE_QUESTIONBANK_SUBCAT":
      return {
        ...state,
        qBSubCat: action.qBSubCat,
      };
    case "UPDATE_QUESTIONBANK_CURRENTQUESTION":
      return {
        ...state,
        currentQuestion: action.currentQuestion,
      };
    case "UPDATE_QUESTIONBANK_ONEBYONE":
      return {
        ...state,
        oneByOne: action.oneByOne,
      };
    case "UPLOAD_QUESTIONBANK_MAINCAT":
      return {
        ...state,
        qBMainCat: action.qBMainCat,
        loaded: action.loaded,
      };
    case "UPLOAD_QUESTIONBANK_SUBCAT":
      return {
        ...state,
        qBSubCat: action.qBSubCat,
        loaded: action.loaded,
      };
    case "UPLOAD_QUESTIONBANK_QUESTIONBANK":
      return {
        ...state,
        QuestionBank: action.QuestionBank,
        loaded: action.loaded,
      };
    case "FETCH_QUESTIONBANK_MAINCATEGORY":
      return {
        ...state,
        mainCatList: action.mainCatList,
        loaded: action.loaded,
      };
    case "FETCH_QUESTIONBANK_SUBCATEGORY":
      return {
        ...state,
        subCatList: action.subCatList,
        loaded: action.loaded,
      };
    // case "FETCH_QUESTIONBANK_SUBCATEGORY_IN_MAIN":
    //     return{
    //         ...state,
    //         subCatListInMain:action.subCatListInMain,
    //         loaded:action.loaded,
    //     }
    case "FETCH_QUESTIONBANK_QBLIST":
      return {
        ...state,
        QBList: action.QBList,
        loaded: action.loaded,
        //QBDetails:[],
      };
    case "FETCH_QUESTIONBANK_QB_IN_LIST":
      return {
        ...state,
        QBDetailsList: action.QBDetailsList,
        loaded: action.loaded,
      };
    case "FETCH_QUESTIONBANK_QB_IN_UPLOAD":
      return {
        ...state,
        QBDetailsUpload: action.QBDetailsUpload,
        loaded: action.loaded,
      };
    case "FETCH_QUESTIONBANK_QB_IN_QUIZ":
      return {
        ...state,
        QBDetailsQuiz: action.QBDetailsQuiz,
        loaded: action.loaded,
      };
    case "DELETE_QUESTIONBANK_CATEGORY":
      return {
        ...state,
        category: action.category,
        loaded: action.loaded,
      };
    case "UPDATE_QUESTIONBANK_CATEGORY":
      return {
        ...state,
        category: action.category,
        loaded: action.loaded,
      };
    case "UPDATE_QB_TO NULL":
      console.log("Reducer called");
      return {
        ...state,
        QBDetailsList: action.QBDetailsList,
        QBDetailsQuiz: action.QBDetailsQuiz,
        // QBDetailsUpload: action.QBDetailsUpload,
      };
    case "UPDATE_AI_QUESTION_ATTRIBUTE":
      return {
        ...state,
        aiQuestionAttribute: action.aiQuestionAttribute,
      };
    case "FETCH_AI_QUESTION":
      return {
        ...state,
        aiQuestions: action.aiQuestions,
        aiLoaded: action.aiLoaded,
        aiQuestionsSuccess: action.aiQuestionsSuccess,
        aiQuestionAttribute: {},
      };
    case "UPDATE_AI_QUESTION":
      return {
        ...state,
        aiQuestions: action.aiQuestions,
        aiLoaded: true,
      };
    case "UPDATE_AI_QUESTION_EDIT":
      return {
        ...state,
        aiQuestions: action.aiQuestions,
      };

    default:
      return state;
  }
};

export default QuestionBankReducer;
