const AllComp = (data) => {
  let atrArr = [];
  let dataArr = [];
  for (const [key, value] of Object.entries(data)) {
    //console.log(`${key}: ${value}`);
    atrArr.push(key);
    dataArr.push(value.length);
  }
  //console.log(atrArr, dataArr);
  return { atr: atrArr, data: dataArr };
};

export default AllComp;
