const INIT_STATE = {
  programLists: [],
  academyList: [],
  success: "",
  loaded: true,
};

const LearningprogramReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "FETCH_PROGRAMSLIST_SUCCESS":
      return {
        ...state,
        programLists: action.programLists,
        loaded: action.loaded,
        persist: action.persist,
      };
    case "LOAD_PROGRAM":
      console.log("program action", action);
      let program1 = action.program;
      if (program1 === undefined) {
        return {
          ...state,
          program: {},
          loaded: action.loaded,
          persist: action.persist,
        };
      } else {
        return {
          ...state,
          program: program1,
          loaded: action.loaded,
          persist: action.persist,
        };
      }
    case "CLONE_PROGRAM":
      let program2 = action.program;
      program2.id = -1;
      program2.users = [];
      program2["clone"] = true;
      if (program2 === undefined) {
        return {
          ...state,
          program: {},
          loaded: action.loaded,
          persist: action.persist,
        };
      } else {
        return {
          ...state,
          program: program2,
          loaded: action.loaded,
          persist: action.persist,
        };
      }
    case "UPDATE_PROGRAM":
      return {
        ...state,
        program: action.program,
      };
    case "UPDATE_ACTIVITY":
      return {
        ...state,
        program: action.program,
      };
    case "PERSIST_PROGRAM":
      return {
        ...state,
        program: action.program,
        loaded: action.loaded,
        persist: action.persist,
        success: action.success,
      };
    case "FETCH_ACADEMY_SUCCESS":
      return {
        ...state,
        academyList: action.academies,
        loaded: action.loaded,
        persist: action.persist,
      };
    case "LOAD_ACADEMY":
      return {
        ...state,
        academy: action.academy,
        loaded: action.loaded,
        persist: action.persist,
      };
    case "UPDATE_ACADEMY":
      return {
        ...state,
        academy: action.academy,
        loaded: action.loaded,
        persist: action.persist,
      };
    case "PERSIST_ACADEMY":
      return {
        ...state,
        academy: action.academy,
        loaded: action.loaded,
        persist: action.persist,
        success: action.success,
      };
    case "DELETE_ACADEMY":
      return {
        ...state,
        academy: action.academy,
        loaded: action.loaded,
        persist: action.persist,
        success: action.success,
      };
    case "UPDATE_SUCCESS_ACADEMY":
      return {
        ...state,
        success: action.success,
      };
    default:
      return state;
  }
};

export default LearningprogramReducer;
