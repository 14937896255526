const INIT_STATE = {
  main: [],
};

const CategoriesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "UPLOAD_MAINCAT":
      return {
        ...state,
        main: action.main,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "UPLOAD_MAINCATEGORY_SUCCESS":
      return {
        ...state,
        main: action.main,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "FETCH_MAINCATEGORY_SUCCESS":
      return {
        ...state,
        mainCatLists: action.mainCatLists,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "FETCH_MAINCATEGORY_SUCCESS_IN_SUBCAT":
      return {
        ...state,
        mainCatLists: action.mainCatLists,
      };
    case "DELETE_MAINCATEGORY_SUCCESS":
      return {
        ...state,
        main: action.main,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "DELETE_SUBCATEGORY_SUCCESS":
      return {
        ...state,
        main: action.main,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "PERSIST_MAINCATEGORY_SUCCESS":
      return {
        ...state,
        main: action.main,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "UPLOAD_SUBCAT":
      return {
        ...state,
        subCat: action.subCat,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "UPLOAD_SUBCATEGORY_SUCCESS":
      return {
        ...state,
        subCat: action.subCat,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "FETCH_SUBCATEGORY_SUCCESS":
      return {
        ...state,
        subCatlists: action.subCatlists,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "FETCH_MAINTYPE_SUCCESS":
      return {
        ...state,
        mainCatType: action.mainCatType,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "PERSIST_SUBCATEGORY_SUCCESS":
      return {
        ...state,
        subCat: action.main,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "UPDATE_SUCCESS":
      return {
        ...state,
        success: action.success,
      };
    default:
      return state;
  }
};

export default CategoriesReducer;
