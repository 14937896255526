/** @format */

import Search from "antd/lib/transfer/search";
import { times } from "lodash";

const INIT_STATE = {
  course: {},
  currentquiz: {},
  answerArray: [],
  searchedCourses: [],
  coursesSequence: [],
  tag: [],
  courses: [], //{"question":"","option1":"","option2":"","option3":"","option4":""},
};

const CourseReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "UPDATE_COURSE":
      return {
        ...state,
        course: action.course,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "FETCH_COURSE_SUCCESS":
      return {
        ...state,
        courses: action.courses,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "FETCH_COURSE_SUCCESS_SEQUENCE":
      return {
        ...state,
        coursesSequence: action.coursesSequence,
        // success: action.success,
        // loaded: action.loaded,
        // persisted: action.persisted,
      };

    case "LOAD_COURSE_SUCCESS":
      let course1 = action.course;
      console.log("LOAD_COURSE_SUCCESS", course1);
      console.log("LOAD_COURSE_SUCCESS", course1.time === undefined);
      if (course1.time === undefined) {
        console.log("called");
        return {
          ...state,
          course: {
            courseName: "",
            desc: "",
            activityType: "Video",
            date: "",
            time: "",
            url: "",
            quizzes: [],
            user: [],
          },
          success: action.success,
          loaded: action.loaded,
          persisted: action.persisted,
        };
      }
      console.log("test time1", course1.time);
      const [time, AMPM] = course1.time.split(" ");
      let [hours, minutes] = time.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (AMPM === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      console.log("test time2", hours, minutes);
      minutes = parseInt(minutes) + 30;
      if (parseInt(minutes) > 59) {
        minutes = parseInt(minutes) - 60;
        hours = parseInt(hours) + 1;
      }
      console.log("test time3", hours, minutes);
      hours = parseInt(hours) - 6;
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      console.log("test time4", hours, minutes);
      course1.time = `2021-12-01T${hours}:${minutes}:00.782Z`;
      console.log("test time5", course1.time);
      return {
        ...state,
        course: course1,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };

    case "UPDATE_CURRENT_QUIZ":
      return {
        ...state,
        currentquiz: action.currentquiz,
        course: action.course,
      };

    case "UPDATE_ALL_QUIZZES":
      return {
        ...state,
        course: action.course,
      };

    case "LOAD_USER_COURSE_SUCCESS":
      console.log("course in reducer", action.course);
      return {
        ...state,
        course: action.course,
        success: action.success,
        loaded: action.loaded,
        persisted: action.persisted,
      };
    case "UPDATE_USER_QUIZ":
      return {
        ...state,
        course: action.course,
      };
    case "UPDATE_USER_QUIZZES":
      return {
        ...state,
        answerArray: action.answerArray,
        //course : action.course
      };
    case "PERSIST_COURSE":
      console.log("test reducer", action, action.success);
      return {
        ...state,
        loaded: action.loaded,
        course: action.course,
        persisted: action.persisted,
        success: action.success,
      };
    case "DELETE_COURSE":
      console.log(action);
      return {
        ...state,
        loaded: action.loaded,
        course: action.course,
        persisted: action.persisted,
        success: action.success,
      };
    case "PERSIST_USER_COURSE":
      return {
        ...state,
        loaded: action.loaded,
        course: action.course,
        persisted: action.persisted,
      };
    case "FETCH_SEARCHED_COURSES":
      return {
        ...state,
        searchedCourses: action.searchedCourses,
        searchedLoaded: action.loaded,
      };
    case "UPDATE_SEARCHED_LOADED":
      return {
        ...state,
        searchedCourses: action.searchedCourses,
        searchedLoaded: action.loaded,
      };
    case "NEXT_PAGE_PDF":
      console.log("nextPageDocument reducer called");
      return {
        ...state,
        currentpage: action.currentpage,
      };
    case "UPDATE_SUCCESS_COURSE":
      return {
        ...state,
        success: action.success,
      };
    case "UPDATE_COURSE_BLANK":
      return {
        ...state,
        course: action.course,
      };
    case "FETCH_TAG_SUCCESS":
      return {
        ...state,
        tag: action.tag,
      };
    default:
      return state;
  }
};
export default CourseReducer;
